(function($){
    'use strict';
    var newsletter = {
        settings: {
            button:          '#newsletter-form__button',
            emailErrorClass: 'newsletter-form__email--error',
            emailImput:      '#newsletter-form__email',
            errorText:       'Invalid email address.',
            errorTextClass:  'error-text',
            form:            '#bulkmail_subscribe_page_239530',
            validEmail:      ''

        },
        init: function () {
            var _this = newsletter;
            if (document.querySelector(_this.settings.emailImput)) {
                document.querySelector(_this.settings.emailImput).addEventListener('change', _this.eventHandler, false);
                document.querySelector(_this.settings.button).addEventListener('click', _this.submitHandler, false);
            }
        },
        eventHandler: function (e) {
            var email = e.target.value,
                regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                _this = newsletter;

            _this.settings.validEmail = regex.test(email);

            if(regex.test(email)) {
                if( e.target.classList.contains(_this.settings.emailErrorClass) ) {
                    e.target.classList.remove(_this.settings.emailErrorClass);

                    e.target.parentNode.removeChild(document.querySelector('.' + _this.settings.errorTextClass));

                }
            } else {
                if( !e.target.classList.contains(_this.settings.emailErrorClass) ) {

                    var error = document.createElement('span');
                
                    error.classList.add(_this.settings.errorTextClass);
                    error.innerHTML = _this.settings.errorText;

                    e.target.parentNode.insertBefore(error, e.target.nextSibling);
                    e.target.classList.add(_this.settings.emailErrorClass);

                }
            }
        },
        submitNewsletter: function () {
            var _this = newsletter,
                xhr = new XMLHttpRequest(),
                url = document.querySelector(_this.settings.form).getAttribute('action'),
                method = document.querySelector(_this.settings.form).getAttribute('method'),
                fromData = $(_this.settings.form).serialize();
            
            document.querySelector(_this.settings.form).innerHTML = '<span class="load-more__button--loading js-load-more__button load-more__button">Load more</span>';

            xhr.open(method, url, true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function() {
                if(xhr.readyState === 4 && xhr.status === 200) {
                    document.querySelector(_this.settings.form).parentNode.innerHTML = '<h2 class="newsletter-form__submit--success">Thank you, you are now subscribed to the TAC newsletter.</h2>';
                } else {
                    document.querySelector(_this.settings.form).parentNode.innerHTML = '<h2 class="newsletter-form__submit--error">Something is wrong.</h2>';
                }
            };
            xhr.send(fromData);
        },
        submitHandler: function (e) {
            e.preventDefault();
            var _this = newsletter;

            if (_this.settings.validEmail) {
                e.target.disabled = true;
                e.target.classList.add('newsletter-form__button--submiting');
                _this.submitNewsletter();
            }
        } 
    };
    
    newsletter.init();
}(jQuery));