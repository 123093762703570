var filterPolicies = {
  $filterLinks: document.querySelectorAll('.search-policies__letters-link'),
  $filterableList: document.querySelector('.search-policies__results-list'),
  $filterableItems: document.querySelectorAll('.search-policies__results-item'),
  config: {
    separator: 'h3',
    displayNone: 'none',
    displayBlock: 'block'
  },

  bind: function () {
    var _this = this

    Array.prototype.forEach.call(this.$filterLinks, function (el) {
      el.addEventListener('click', _this.clickHandler.bind(_this))
    })

    window.addEventListener('popstate', this.historyPopHandler.bind(this))
  },

  clickHandler: function (event) {
    event.preventDefault()

    var letter = event.currentTarget.text
    var results = this.calculateResults(letter)

    this.toggleResults(results)
    this.historyPushState(letter)
  },

  calculateResults: function (letter) {
    var filter = letter.length > 1
      ? this.$filterableItems
      : this.$filterableList.querySelector('a[name="letter_' + letter + '"]')

    var results = filter 
      ? filter.length
        ? filter
        : this.nextUntil(filter.parentElement, this.config.separator) 
          : []

    return results
  },

  toggleResults: function (results) {
    var _this = this

    if (results.length) {
      Array.prototype.forEach.call(this.$filterableItems, function (el) {
        el.style.display = _this.config.displayNone
      })
    }

    Array.prototype.forEach.call(results, function (el) {
      el.style.display = _this.config.displayBlock
    })
  },

  historyPushState: function (url) {
    history.pushState(url, null, '#' + url)
  },

  historyReplaceState: function (url) {
    history.replaceState(url, null, '#' + url)
  },

  historyPopHandler: function (evt) {
    if (window.history.state !== null) {
      var letter = evt.state
      var results = this.calculateResults(letter)

      this.toggleResults(results)
    }
  },

  nextUntil: function (elem, selector, filter) {
    var siblings = [];

    elem = elem.nextElementSibling;

    while (elem) {
      if (elem.matches(selector)) break;

      if (filter && !elem.matches(filter)) {
        elem = elem.nextElementSibling;
        continue;
      }

      siblings.push(elem);
      elem = elem.nextElementSibling;
    }

    return siblings;
  },

  init: function () {
    this.bind()

    if (this.$filterLinks.length && window.location.hash) {
      var hash = window.location.hash.replace(/#/g, '')
      var results = this.calculateResults(hash)

      this.historyReplaceState(hash)
      this.toggleResults(results)
    }
  }
};

(function(){
  filterPolicies.init()
}());


if (!Element.prototype.matches) {
  Element.prototype.matches = 
  Element.prototype.matchesSelector || 
  Element.prototype.mozMatchesSelector ||
  Element.prototype.msMatchesSelector || 
  Element.prototype.oMatchesSelector || 
  Element.prototype.webkitMatchesSelector ||
  function(s) {
    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
      i = matches.length
    while (--i >= 0 && matches.item(i) !== this) {}
    return i > -1
  };
}
