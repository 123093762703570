function tabClickHandler(e) {
    e.preventDefault();

    removeActiveClass(landingTabs.tabs);
    removeActiveClass(landingTabs.contents);

    const targetContentId = this.hash;
    this.classList.add('active');
    document.getElementById(targetContentId).classList.add('active');
}

function removeActiveClass(nodes) {
    Array.prototype.forEach.call(nodes, function(node){ 
        node.classList.remove('active'); 
    });
}

const landingTabs = {
    tabs: document.querySelectorAll('.landing-tabs__tab-js'),
    contents: document.querySelectorAll('.landing-tabs__content')
}

function debounceListener(fun, evType, duration) {

    return function handler(e) {
        window.removeEventListener(evType, handler);

        fun(e);

        setTimeout(function() {
            window.addEventListener(evType, handler);
        }, duration);
    }
}

if(landingTabs.tabs) {
    Array.prototype.forEach.call(landingTabs.tabs, function(tab){
        tab.addEventListener("click", tabClickHandler);
    });

    if(window.innerWidth < 480) {
        Array.prototype.forEach.call(landingTabs.contents, function(content, index) {
            landingTabs.tabs[index].parentNode.insertBefore(content, landingTabs.tabs[index].nextElementSibling);
        });
    }

    window.addEventListener("resize", debounceListener(function(e) {
        if(window.innerWidth < 480) {
            Array.prototype.forEach.call(landingTabs.contents, function(content, index) {
                landingTabs.tabs[index].parentNode.insertBefore(content, landingTabs.tabs[index].nextElementSibling);
            });
        } else {
            const contentsWrapper = document.querySelector('.landing-tabs__content-wrapper');
            Array.prototype.forEach.call(landingTabs.contents, function(content) {
                contentsWrapper.appendChild(content);
            });
        }
    }, "resize", 200));
}