(function ($) {

    var Tabs = {
        $tabsControls: $('#tabs__controls'),
        $tabsContents: $('.tabs__tab-content'),
        $tabsItems: $('#tabs__controls').find('li'),

        $innerTabsContents: $('.inner-tabs__tab-content'),
        $innerTabsItems: $('.inner-tabs__controls').find('li'),

        /*
         * tabSwitch
         * tab switch for main tab nav
         */
        tabSwitch: function (event) {
            event.preventDefault();
            var $target = $(event.target);
            var $matchingTab = $('#' + $target.data('tab-control'));

            // set proper hash (default prevented)
            var newHash = '#' + $target.data('tab-control');
            history.replaceState(null, null, newHash);

            // select tab
            if (!$target.hasClass('current')) {
                this.$tabsItems.find('a').removeClass('current');
                $target.addClass('current');

                this.$tabsContents.removeClass('current').addClass('hidden');
                $matchingTab.addClass('current').removeClass('hidden');
            }
        },
        /*
         * tabSwitcherInner
         * tab switch for inner tabs
         */
        tabSwitchInner: function (event) {
            event.preventDefault();
            var $target = $(event.target);
            // select tab
            if (!$target.hasClass('current')) {
                $target.closest('.inner-tabs__controls').find('a').removeClass('current');
                $target.closest('.inner-tabs').find('.inner-tabs__tab-content').removeClass('current').addClass('hidden');
                $target.closest('.inner-tabs').find('#' + $target.data('tab-control')).addClass('current').removeClass('hidden');
                $target.addClass('current');

            }

            // check if service main-nav tab exists, 
            // create hash location e.g. #payment/tab-2
            if (this.$tabsControls.length) {
                var hash = window.location.hash.split('/');
                var newHash = hash[0] + '/' + $target.data('tab-control');
                history.replaceState(null, null, newHash);
            }
            // inner-tabs only
            // set proper hash (default prevented)
            else {
                var newHash = '#' + $target.data('tab-control');
                history.replaceState(null, null, newHash);
            }
        },
        /*
         * autoTabSelect
         * select tab using url#hash
         */
        autoTabSelect: function () {

            var hash = window.location.hash.replace('#', '').split('/');

            if (hash.length === 2) {
                // inner tab select
                var tab1ID = '#' + hash[0] + '-link';
                var tab2ID = '#' + hash[1] + '-link';

                $(tab1ID).trigger('click'); // #payment-link click
                $('#' + $(tab1ID).data('tab-control')).find(tab2ID).trigger('click');
            }

            if (hash.length === 1) {
                var tab1ID = '#' + hash[0] + '-link';
                $(tab1ID).trigger('click');
            }

        },
        /*
         * mobileTabs
         * init if main tabs number > 2
         */
        mobileTabs: function () {
            // Add mobile view classes
            this.$tabsItems.eq(1).nextAll().addClass('submenu-item');

            // Add more button
            this.$tabsItems.eq(1).after($('<li class="submenu-toggle"><a href="#" id="submenu-toggle">More<i class="icon"></i></a></li>'));

            // Bind newly created submenu-toggle
            $('#submenu-toggle').on('click', this.submenuToggle.bind(this));
        },
        /*
         * submenuToggle
         * more button (for submenu) handler
         */
        submenuToggle: function () {
            var $submenuToggle = $('#submenu-toggle');
            var $submenu = $('.submenu-item');

            if ($submenuToggle.hasClass('active')) {
                $submenuToggle.removeClass('active');
                // hide submenu
                $submenu.hide();
            } else {
                $submenuToggle.addClass('active');
                // show submenu
                $submenu.show();
            }

        },
        /* 
        * scrollToTab
        * handle click linked to tab
        */
        scrollToTab: function(event) {

            var $target = $(event.target);
            var tabHref = $target.attr('href');
            var $tab = $(tabHref + '-link');

            // if tab exists
            if($tab.length) {
                event.preventDefault();
                // scroll up
                $('html').animate({
                    scrollTop: this.$tabsControls.offset().top - 150},
                    500,
                    function() {
                        // open tab
                        $tab.trigger('click');
                        // if tab-content-related click, scroll to specifc anchor
                        if($target.hasClass('tab-content-related')) {
                            var $specificTabContent = $($target.data('related-to'));
                            // IF in Accordion content, open it
                            if($specificTabContent.hasClass('ui-accordion-content') && !$specificTabContent.hasClass('ui-accordion-content-active')) {
                                $specificTabContent.prev().trigger('click');
                            }
                            $('html').animate({
                                scrollTop: $specificTabContent.offset().top - 150},
                                500
                            );
                        }
                    }
                );
            }
        },
        /*
         * Init
         */
        init: function () {
            
            // hide tabs content on default
            this.$tabsContents.not('.current').addClass('hidden');
            this.$innerTabsContents.not('.current').addClass('hidden');

            // init mobile if need
            if (this.$tabsItems.length > 2) {
                this.mobileTabs();
            }
            // main nav tabs
            this.$tabsItems.find('a').on('click touch', this.tabSwitch.bind(this));
            // inner tabs
            this.$innerTabsItems.find('a').on('click touch', this.tabSwitchInner.bind(this));
            // auto tab select
            this.autoTabSelect();
            // click and open tab

            // get all links with # without inner-tabs 
            this.$tabsContents.find("a[href*=#]").not(".inner-tabs__controls a").on('click', this.scrollToTab.bind(this));  
        }
    };

    // Init only on service pages
    if ($('#service-overview').length || $('.inner-tabs').length) {
        Tabs.init();
    }


})(jQuery);