var youtubePlayer = (function(){
    var settings = {
        ytLinks:  document.querySelectorAll('.js-youtube'),
        ytPlayer: null
    };

    function init() {
        if ( settings.ytLinks ) {
            _addYoutubeAPI();
            _addEvent();
            _backgrountImgIE();
        }
    }

    function _backgrountImgIE(){
        var us = window.navigator.userAgent;
        
        if( us.indexOf('MSIE ') > 0 || us.indexOf('Trident/') > 0 || us.indexOf('Edge/') > 0 ) {
            
            [].forEach.call(settings.ytLinks, function(link) {
                var src = link.querySelector('img').getAttribute('src');
                
                link.children[0].classList.add('youtube__ie-background');
                link.children[0].style.backgroundImage = 'url("' + src + '")';
            });

        }
    }

    function _addYoutubeAPI() {
        var tag = document.createElement('script');
        tag.src = "//www.youtube.com/iframe_api";

        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    function _onYouTubePlayer(playerID, ytID) {
        settings.ytPlayer = new YT.Player(playerID, {
            height:  '',
            width:   '100%',
            videoId: ytID,
            events:  {
                'onReady': _onPlayerReady
            }
        });
    }

    function _onPlayerReady(event) {
        event.target.playVideo();
    } 

    function _handleClick(ev) {
        ev.preventDefault();

        this.parentNode.classList.add('youtube__item--playing');
        _onYouTubePlayer(this.getAttribute('id'), this.getAttribute('data-yt-id'));
    }

    function _addEvent() {
        [].forEach.call(settings.ytLinks, function(ytLink) {
            ytLink.addEventListener('click', _handleClick, false);
        });
    }

    return {
        init: init
    };

})();

youtubePlayer.init();
