
var searchBarFilter = (function () {
    
    var settings = {
        filterLink: 'js-search-bar__filter-menu-link',
        url:        'https://tac-search.clients.squiz.net/s/search.json?collection=tac-corporate-news-push&query=!padrenull',
        facetUrl:   'collection=tac-corporate-news-push&sort=date&num_ranks=8',
        mediList:   '.js-filter--media',
        dateList:   '.js-filter--date'
    };

    function init(){
        if(document.querySelector('.' + settings.filterLink)) {
            _searchBarFilter();
        }
    }

    function _findChildren(el){
        return Array.prototype.filter.call( el.parentNode.children, function(child){
            return child !== el;
        });
    }

    function _handlerClick(el) {
        if( el.target.classList.contains(settings.filterLink) ){

            el.preventDefault();

            if( el.target.classList.contains('search-bar__filter-menu-link--active') ){
                el.target.classList.remove('search-bar__filter-menu-link--active');

                _findChildren( el.target )[0].classList.remove('search-bar__filter-list--active');
                _findChildren( el.target )[0].setAttribute('aria-hidden', 'true');
                _findChildren( el.target )[0].setAttribute('aria-expanded', 'false');
            } else {

                _closeFilter(settings.filterLink);
                el.target.classList.add('search-bar__filter-menu-link--active');
                el.target.setAttribute('aria-expanded', 'true');

                _findChildren( el.target )[0].classList.add('search-bar__filter-list--active');
                _findChildren( el.target )[0].setAttribute('aria-hidden', 'false');
            }

        } else {
            _closeFilter(settings.filterLink);
        }
    }

    function _closeFilter(els){
        Array.prototype.forEach.call( document.querySelectorAll('.' + els), function(el){
            el.classList.remove('search-bar__filter-menu-link--active');
            el.setAttribute('aria-expanded', 'false');

            _findChildren( el )[0].classList.remove('search-bar__filter-list--active');
            _findChildren( el )[0].setAttribute('aria-hidden', 'true');
        });
    }

    function _searchBarFilter() {
        document.addEventListener('click', _handlerClick, false);
    }

    return {
        init: init
    };

})();

var loadMoreResults = (function(){
    var settings = {
        tilesSelector: '.js-news-tiles__container',
        button:        document.querySelector('.js-load-more__button'),
        next:          document.querySelector('.js-load-more__button') ? document.querySelector('.js-load-more__button').getAttribute('data-next'): null,
        end:           document.querySelector('.js-load-more__button') ? document.querySelector('.js-load-more__button').getAttribute('data-end'): null,
        url:           window.location,
        numRank:       8, 
        designName:    'blank',
        result:        null
    };

    var newsTiles = document.querySelector(settings.tilesSelector);

    function init(){
        if(settings.button) {
            _loadMoreResults();
        }
    }

    function _ajaxRequest(url){
        var request = new XMLHttpRequest();

        request.open('GET', url, true);
        
        request.onload = function() {
            if (request.status >= 200 && request.status < 400) {

                _insertNewResult(request.responseText);        
                _buttonHandler();

            } else {
                console.log('Error ' + request.status);
            }
        };
        
        request.onerror = function() {
            console.log('Request error');
        };
        
        request.send();
    }

    function _insertNewResult(resp){
        var parser = new DOMParser(),
            result = parser.parseFromString(resp, 'text/html').querySelector(settings.tilesSelector);

        settings.result = newsTiles.innerHTML;
        newsTiles.innerHTML = settings.result + result.innerHTML;
    }

    function _buttonHandler() {
        if ( Number(settings.end) - Number(settings.next) < settings.numRank ) {
            settings.next = Number(settings.next) + (Number(settings.end) - Number(settings.next));//1;
            settings.next.toString();
        } else {
            settings.next = Number(settings.next) + settings.numRank;
            settings.next.toString();
        }
        if (Number(settings.next) < Number(settings.end)) {
            settings.button.disabled = false;
            settings.button.classList.remove('load-more__button--loading');
        } else {
            settings.button.parentNode.style.display = 'none';
            settings.button.classList.remove('load-more__button--loading');
        }
        settings.button.setAttribute('data-next', settings.next);
    }

    function _clickHandler() {
        var url = null;

        settings.button.disabled = true;
        settings.button.classList.add('load-more__button--loading');

        if(settings.url.href.split('?').length > 1) {
            url = settings.url.href.split('?')[0] + '?SQ_DESIGN_NAME=' + settings.designName + '&' + settings.url.href.split('?')[1] + '&start_rank=' + settings.next;
        } else {
            url = settings.url.href + '?SQ_DESIGN_NAME=' + settings.designName + '&start_rank=' + settings.next;
        }

        _ajaxRequest(url);
    }

    function _loadMoreResults(){
        if ( settings.button ) {
            settings.button.addEventListener('click', _clickHandler, false);
        }
    }
    return {
        init: init
    };

})();

var categoryTilesLink = (function(){
    var settings = {
        categorySelector: '.news-tiles__item-category',
        dataAttr:         'data-url',
        latesNews:        '.latest-news',
        mediaURl:         'data-media-url'
    };

    var categorySelectors = document.querySelectorAll(settings.categorySelector),
        dataSelectors = document.querySelectorAll(settings.categorySelector + '[' + settings.dataAttr + ']'),
        mediaRelease = '';

    function _ifLatestNews() {
        if (document.querySelector(settings.latesNews)) {
            mediaRelease = document.querySelector(settings.latesNews).getAttribute(settings.mediaURl);
        } else {
            mediaRelease = '';
        }
    }
    
    function _clickHandler(e) {
        e.preventDefault();

        _ifLatestNews();

        if (window.location.search === '') {
            window.open(mediaRelease + '?collection=tac-corporate-news-push&query=!padrenull&sort=date&num_ranks=8&f.News+Category%7CnewsCategoryValue=' + e.target.getAttribute(settings.dataAttr).split(' ').join('+'), '_self');
        } else {
            if (window.location.search.indexOf('f.News+Category%7CnewsCategoryValue') >= 0) {
                window.open(window.location.search.split('f.News+Category%7CnewsCategoryValue')[0] + 'f.News+Category%7CnewsCategoryValue=' + e.target.getAttribute(settings.dataAttr).split(' ').join('+'), '_self');
            } else {
                window.open(window.location.search + '&collection=tac-corporate-news-push&sort=date&num_ranks=8&f.News+Category%7CnewsCategoryValue=' + e.target.getAttribute(settings.dataAttr).split(' ').join('+'), '_self');
            }
        }
    }

    function _categoryTilesFiltr() {
        if( categorySelectors.length > 0 && dataSelectors.length > 0 ) {
            Array.prototype.forEach.call(categorySelectors, function(categorySelector) {
                categorySelector.addEventListener('click', _clickHandler, false);
            });
        }
    }

    function init(){
        _categoryTilesFiltr();
    }

    return {
        init: init
    };

})();

searchBarFilter.init();
loadMoreResults.init();
categoryTilesLink.init();
