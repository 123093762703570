(function($){
    'use-strict';

    var mediaroomCarousel = {
        globals: {
            progressTime:                5,
            mainClass:                   $('.mediaroom-carousel'),
            progressBarClass:            $('.carousel-progress__bar'),
            carouselSetClass:            $('.carousel-slides'),
            carouselItems:               $('.carousel-slides__item'),
            carouselPauseBtnClass:       '.carousel-controls__btn--pause',
            carouselStartBtnClass:       '.carousel-controls__btn--play',
            carouselControlsClass:       '.carousel-controls',
            carouselControlsActiveClass: 'carousel-controls--active',
            carouselDotsClass:           '.slick-dots li',
            thumbClass:                  $('.carousel-column__thumb'),
            dotsClass:                   '.slick-dots',
            progressTick:                '',
            percentTime:                 0,
            isNoPause:                   true,
            isNoBtnPause:                true
        },

        init: function() {
            var self = this;
            if(self.globals.mainClass.length) {
                self.activeCarousel();
            }
        },

        activeCarousel: function () {
            var self = this;
            self.globals.carouselSetClass.slick({
                dots:             true, 
                infinite:         true,
                fade:             true,
                autoplay:         false,
                slidesToShow:     1,
                adaptiveHeight:   true,
                useCSS:           false,
                arrows:           true,
                pauseOnDotsHover: true,
                accessibility:    false
            });

            self.triggers();
            if(self.globals.carouselItems.length > 1) {
                self.activeControls();
                self.resize();
            }
        },

        activeControls: function () {
            var self = this;
            $(self.globals.carouselControlsClass).addClass(self.globals.carouselControlsActiveClass);
        },

        triggers: function () {
            var self = this;
            $(self.globals.carouselPauseBtnClass).on('click', function () {
                $(this).hide();
                $(self.globals.carouselStartBtnClass).show();
                self.globals.isNoBtnPause = false;
            });
            $(self.globals.carouselStartBtnClass).on('click', function () {
                $(this).hide();
                $(self.globals.carouselPauseBtnClass).show();
                self.globals.isNoBtnPause = true;
            });

            self.globals.mainClass.on({
                mouseenter: function() {
                    self.globals.isNoPause = false;
                },
                mouseleave: function() {
                    self.globals.isNoPause = true;
                }
            });

            $(self.globals.carouselDotsClass).on('click', function () {
                self.startProgressBar();
            });
        },

        resize: function () {
            var self = this;
            if(window.innerWidth < 480) {
                self.resetProgressBar();
                self.calcDotsTopPosition(true);
            } else {
                self.startProgressBar();
                self.calcDotsTopPosition(false);
            }
        },

        calcDotsTopPosition: function (arg) {
            var self = mediaroomCarousel;
            if(arg) {
                var $height = self.globals.thumbClass.height() * 0.9;
                $(self.globals.dotsClass).css('top', $height + 'px');
            } else {
                $(self.globals.dotsClass).css('top', '');
            }
        },

        startProgressBar: function () {
            var self = this;
            self.resetProgressBar();
            self.globals.percentTime = 0;
            self.globals.isNoPause = true;
            self.globals.progressTick = setInterval(self.setIntervalProgressBar, 10);
        },

        setIntervalProgressBar: function () {
            var self = mediaroomCarousel;
            if(self.globals.isNoPause && self.globals.isNoBtnPause) {
                self.globals.percentTime += 1 / (self.globals.progressTime + 0.1);
                self.globals.progressBarClass.css({
                    width: self.globals.percentTime + '%'
                });

                if(self.globals.percentTime >= 100)
                {
                    self.globals.carouselSetClass.slick('slickNext');
                    self.startProgressBar();
                }
            }
        },

        resetProgressBar: function () {
            var self = this;
            self.globals.progressBarClass.css({
                width: 0 + '%'
            });
            clearTimeout(self.globals.progressTick);
        }
    };

    $(document).ready(function(){
        mediaroomCarousel.init();
    });

    $(window).resize(function(){
        mediaroomCarousel.resize();
    });

    $(document).load(function(){
        mediaroomCarousel.resize();
    });

})(jQuery);