/*global jQuery*/
/**
 * {{name}}
 * Global JS
 *
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * updated: {{date}}
 */
/*
 * Table of Contents
 * 1. Global
 * 2. InitAccessibility
 * 3. InitGlobal
 * 4. InitResponsive
 * 5. InitDesktop
 * 5.1. InitDesktopNavigation
 * 5.2. DestroyDesktop
 * 6. InitMobile
 * 6.1. InitMobileNavigation
 * 6.2. DestroyMobile
 * 7. InitTabsAccordions
 * 8. InitCarousels
 * 9. InitVideo
 * 10. Page Specific
 * 10.1. InitContactUs
 * 10.2. InitCampaigns
 * 10.3. InitDocumentsForms
 * 10.4. InitEvents
 * 10.5. InitLocateServiceProvider
 * 11. InitFunnelback
 * 12. Functions
 * 13. Native Prototype Extension
 * 14. Performance Ajax
 * 15. Popup
 * 16. Search TAC-42
 * 
 * Modules
{{toc}}
 */
//'use strict';

var mobile = false;
var desktop = false;
var cssEnabled = true;

/*
 --------------------
 1. Global
 --------------------
 */

$('body').removeClass('no-js').addClass('js-enabled');


// flowchart functions

function arrowInsert(parent, index, block, rowIndex, rowSkip, cssClass) {
    var count = index + block,
        rowPos = rowIndex + rowSkip;
    $('.row:eq(' + rowPos + ') .block:eq(' + count + ')', parent).append('<span class="arrow ' + cssClass + '"></span>');
};

function setWidth() {

    if ($('flowchart').width() <= 473) {
        var widths = [150, 200, 200, 160, 150];
    } else {
        var widths = [150, 220, 220, 190, 150];
    };

    $('.row').each(function () {
        var blocks = $(this).children('.block').length;
        $('.block', this).css('width', widths[blocks] + 'px');
    });
};

function drawRelationships(block) {
    var parent = block[0];
    $('.block:not(.js-enabled)', block).each(function () {
        $(this).addClass('js-enabled');
        $(this).wrapInner("<div class='blockinner'></div>");
        if ($(this)[0].hasAttribute("desc")) {
            var directions = $(this).attr('desc').replace(/\s/g, '').toLowerCase(),
                directions = directions.split(','),
                index = $(this).index(),
                rowIndex = $(this).closest('.row').index();

            //Sets how many blocks to count for arrow
            var count = $(this).closest('.row').children('.block').length;
            var skip = [1, 1, 0, -1, -1];
            var row4 = [1, 1, 0, -1, -1];
            if (count > 3) skip = row4;

            for (var i = 0; i < directions.length; i++) {
                var direction = directions[i];
                //Build starting span
                $(this).append('<span class="arrow ' + direction + '"></span>');

                switch (directions[i]) {
                    case 'east':
                        arrowInsert(parent, index, skip[0], rowIndex, 0, 'fromWest');
                        break;
                    case 'southeast':
                        arrowInsert(parent, index, skip[1], rowIndex, 1, 'fromNorthwest');
                        break;
                    case 'south':
                        arrowInsert(parent, index, skip[2], rowIndex, 1, 'fromNorth');
                        break;
                    case 'southwest':
                        arrowInsert(parent, index, skip[3], rowIndex, 1, 'fromNortheast');
                        break;
                    case 'west':
                        arrowInsert(parent, index, skip[4], rowIndex, 0, 'fromEast');
                        break;
                    case 'southlong':
                        arrowInsert(parent, index, skip[2], rowIndex, 2, 'fromNorth');
                        break;
                    default:
                }
            };

        }

    });
    $(".flowchart").show();
};

$(function () {

    // apply mobile or desktop class
    checkWidth(true);

    InitAccessibility();
    InitGlobal();

    // page specific initialisation
    InitContactUs();
    InitCampaigns();
    InitDocumentsForms();
    InitEvents();
    InitLocateServiceProvider();
    InitResponsive(); // responsive

    // viewport initialisation
    $(window).resize(function () {
        checkWidth(false);
        InitResponsive();
    });

    // site wide tabs accordions
    InitTabsAccordions();

    InitVideo();
    InitFunnelback();

});

/*
 --------------------
 2. InitAccessibility
 --------------------
 */
function InitAccessibility() {

    // check if css is enabled
    if ($('.statelogo').css('float') == "right")
        cssEnabled = true;

    // check to see if images are enabled
    var image = new Image();
    var $body = $('body');
    // Static URL temporarily applied due to FB
    //var baseUrl = location.protocol + '//' + location.host,
    // staticUrl = 'http://www.tac.vic.gov.au',
    var baseUrl = location.protocol + '//www.tac.vic.gov.au';
    var externalURL = baseUrl + '/_design/scripts/px.gif';
    // externalURL = staticUrl + '/_design/scripts/px.gif';
    // Add images-off class
    $body.addClass('images-off');
    image.onload = function () {
        if (image.width > 0) {
            $body.removeClass('images-off').addClass('images-on');
        }
    };
    image.src = externalURL;

};

/*
 --------------------
 3. InitGlobal
 --------------------
 */
function InitGlobal() {

    // input placeholder text
    $('[placeholder]').not('.search-bar__input').val(function () {
        return $(this).attr('placeholder')
    });
    $('[placeholder]').not('.search-bar__input').focus(function () {
        if ($(this).val() == $(this).attr('placeholder')) {
            $(this).val('');
        }
    }).blur(function () {
        if ($(this).val() == '') {
            $(this).val($(this).attr('placeholder'));
        }
    }).change(function () {
        if ($(this).val() != '')
            $(this).data('changed', true);
        else
            $(this).data('changed', false);
    });

    // stop the global search from searching the default value
    $('.header .search input[type=submit]').click(function (_e) {

        var changed = $(this).parent().find('input[type=text]').data('changed');
        if (changed == false || changed == null) {
            return false;
        }

    });

    // override and set styles for any tables with a defined width
    var table = $('table');
    table.css({
        "width": table.attr('width'),
        "height": table.attr('height')
    });

    // add javascript buttons
    $('.sitetools').append("<li><a href='javascript:void(0);' class='share addthis_button' tabindex='-1'><span></span>Share</a></li>");

    //init select redirect functionality 
    var redirectCount = 1;
    $('.redirect-links').each(function () {
        $(this).before("<div class='redirect-select redirect" + redirectCount + "'><div class='select-wrapper'><span></span><label for='other-websites'>Other Websites</label><select title='other websites' name='other-websites'><option value='./' selected>Other TAC websites</option></select></div><input class='orange-btn-primary redirect-btn' type='submit' name='sumbit' value='Go' /></div>");
        $(this).find("a").each(function () {
            var linkurl = $(this).attr("href");
            var linktext = $(this).text();
            $(".redirect" + redirectCount).find("select").append("<option value='" + linkurl + "'>" + linktext + "</option>");
            $(this).remove();
        })
        $(this).remove();
        redirectCount++;
    });

    $(".redirect-btn").click(function (e) {
        e.preventDefault();
        redirectURL = $(this).parent(".redirect-select").find("select option:selected").attr("value");
        window.location = redirectURL;
    });

};

/*
 --------------------
 4. InitResponsive
 --------------------
 */
function InitResponsive() {

    // mobile
    if ($('html').hasClass('mobile')) {

        // one time init on change
        if (mobile == false) {

            mobile = true, desktop = false;
            DestroyDesktop();
            InitMobile();

        }

        // desktop
    } else {

        // one time init on change
        if (desktop == false) {

            desktop = true, mobile = false;
            DestroyMobile();
            InitDesktop();

        }

    }

};

/*
 --------------------
 5. InitDesktop
 --------------------
 */
function InitDesktop() {

    // remove any mobile features
    $('.navigation').css('display', '');

    $('.header').removeClass('showbread');
    $('.mobile-menu-btn').unbind('click');
    $('.mainmenu .toggle').unbind('click');

    $('.carousel').trigger('destroy', true);

    // setup the sub navigation
    var subnavwidth = $('.submenu > ul').width();
    var elements = $('.submenu > ul > li');

    var elementsWidth = 0;

    elements.each(function () {
        elementsWidth += $(this).width() + 20;
    });

    if (elementsWidth > subnavwidth) {

        var newelementswidth = Math.floor(subnavwidth / elements.length);
        elements.width(newelementswidth - 20);

        $('.submenu > ul').addClass('adjusted');

    }

    // set the heights of all divs with full height class
    $('.full-inner').height(function () {
        return $(this).height();
    });

    // add offscreen to any elements that are display none that need full height
    $('.megamenu').css({
        'display': 'block',
        'left': '-9999px'
    });

    // set the heights of all divs with full height class
    $('.full-height').height(function () {
        return $(this).outerHeight();
    });

    $('.megamenu').css({
        'display': '',
        'left': ''
    });

    // init desktop features
    InitDesktopNavigation();
    InitCarousels();

    $('#policy-tabs').show();

};

/*-- 5.1 InitDesktopNavigation --*/
function InitDesktopNavigation() {

    var navigation = $('.navigation');

    var posTop = navigation.position().top;
    var height = navigation.height();

    // Setup sticky menu
    $(window).scroll(function () {

        if ($(window).scrollTop() > posTop) {
            navigation.addClass('fixed');
            navigation.prev().css({
                'margin-bottom': height + 'px'
            });
        } else {
            navigation.removeClass('fixed');
            navigation.prev().css({
                'margin-bottom': 0
            });
        }

    });

    $(navigation).find("li a").on('focusin', function () {
        $('.mainmenu > li').removeClass('hovered');
        $(this).parents('li').addClass('hovered');
    });

    // Hide megamenu when user focuses out of it (TAC-68)
    $(navigation).on('focusout', function () {
        setTimeout(function () {
            if ($(document.activeElement).parents('.navigation').length === 0) {
                $('.mainmenu > li').removeClass('hovered');
            }
        }, 1);
    });

    // Submenu focus (TAC-73)
    $('.submenu a').focus(function () {
        $(this).parent('li').css({
            "background-color": "#fff",
            "border-bottom": "2px solid #009ce0"
        });
    }).blur(function () {
        if (!$(this).parent('li').hasClass('active')) {
            $(this).parent('li').css({
                "background-color": "#dcdfe3",
                "border-bottom": "2px solid transparent"
            });
        }

    });

    // Skip links toggle focus (TAC-71)
    $('.skip-links').find("li a").on('focusin', function () {
        $(this).parents('.skip-links').addClass('skip-links__focused');
    });

    $('.skip-links').on('focusout', function () {
        setTimeout(function () {
            if ($(document.activeElement).parents('.skip-links').length === 0) {
                $('.skip-links').removeClass('skip-links__focused');
            }
        }, 1);
    });

    // HoverIntent
    var $navigationItems = $('.navigation > ul > li');
    $navigationItems.each(function (i, el) {
        hoverintent(el, function () {
            $navigationItems.removeClass('hovered');
            $(el).addClass('hovered');
        }, function () {
            $(el).removeClass('hovered');
        });
    });


    /* 030913 Tsun: Commented due to conflicts with tabbing
     $(navigation).find("li a").on('blur, click, mouseout, focusout', function (_e) {
     _e.preventDefault();
     $(this).parents('li').removeClass('hovered');
     });
     */
};

/*-- 5.2 DestroyDesktop --*/
function DestroyDesktop() {

};

/*
 --------------------
 6. InitMobile
 --------------------
 */
function InitMobile() {

    // remove the heights of all divs with full height class
    $('.full-height').css("height", "");

    // remove fixed class from navigation and the previous element bottom
    $('.navigation').removeClass('fixed').prev().css("margin-bottom", 0);

    // remove scroll function
    $(window).unbind('scroll');

    // remove subnavigation styles 
    $('.submenu > ul').addClass('adjusted');
    $('.submenu > ul > li').width('auto');

    $('.carousel').trigger('destroy', true);

    // init mobile features
    InitMobileNavigation();
    InitCarousels();

};

/*-- 6.1 InitMobileNavigation --*/
function InitMobileNavigation() {

    // mobile menu button
    $('.mobile-menu-btn').click(function (_e) {

        _e.preventDefault();
        if (!$('.navigation:visible').length) {

            $('.header').removeClass('showsearch').addClass('showbread');
            $('.mobile-search-btn').removeClass('hide');
            $(this).addClass('close');
            $('.navigation').stop(true, true).show();

        } else {

            $('.header').removeClass('showsearch showbread');
            $(this).removeClass('close');
            $('.navigation').stop(true, true).hide();

        }

    });

    // search button
    $('.mobile-search-btn').click(function (_e) {

        _e.preventDefault();
        if (!$('.header').hasClass('showsearch')) {
            $('.header').removeClass('showbread').addClass('showsearch');
            $(this).addClass('hide');
        }

    });

    $('.header input[name=query]').on('keyup', function () {

        if ($(this).val() != "") {
            $('.header .search').addClass('full');
            return;
        }

        $('.header .search').removeClass('full');

    });

    // search button
    $('.mobile-close-btn').click(function (_e) {

        _e.preventDefault();
        if ($('.header').hasClass('showsearch')) {
            $('.header input[name=query]').val("");
            $('.header .search').removeClass('full');
        }

    });

    $('.mainmenu .toggle').click(function (_e) {

        _e.preventDefault();
        $(this).toggleClass('open close');
        $(this).parent().find('ul').first().stop(true, true).toggle();

    });

};

/*-- 6.2 DestroyMobile --*/
function DestroyMobile() {

};

/*
 --------------------
 7. InitTabsAccordions
 --------------------
 */
function getAnchor(url) {
    var index = url.lastIndexOf('#');
    if (index != -1) {
        return url.substring(index);
    } else {
        return false;
    }
}

function InitTabsAccordions() {

    // look for anything with .tabs.desktop-only and duplicate it
    $('.tabs.desktop-only').each(function (_indx, _tabs) {

        // create the accordion 
        var accordion = $('<div/>', {
            'class': 'accordion mobile-only blue'
        });

        // get all the headings
        $(this).find('> ul:first-child > li > a').each(function (_indx, _link) {

            // get the href for the corresponding tab
            var href = $(this).attr('href');
            accordion.append('<h3>' + $(this).text() + '</h3>');
            var body = $($(_tabs).find(href)).clone();
            body.attr("id", "m" + body.attr("id"));
            accordion.append(body);

        });

        $(this).after(accordion);

    });

    //Loading Accordion and Tabs
    $(".accordion").accordion({
        heightStyle: 'content',
        collapsible: true,
        active: false
    });

    $(".tabs").tabs({
        heightStyle: "content",
        show: true
    });

    // open accordion from url
    var currentAnchor = getAnchor(location.href);
    if (currentAnchor !== false) {
        $(currentAnchor).show();
    }

    // open accordion from .open class
    $('.accordion h2').each(function (i, item) {
        if ($(this).hasClass('open')) {
            $(this).parent().accordion('option', 'active', i);
        }
    });
    $('.accordion h3').each(function (i, item) {
        if ($(this).hasClass('open')) {
            $(this).parent().accordion('option', 'active', i);
        }
    });

    // wrap tables in a div
    $('table').wrap("<div class='table'>");

    // loop through and add table class
    $('.ui-tabs-panel, .ui-accordion-content').each(function (_indx, _body) {

        var table = $(_body).find('table');

        if (table.length) {
            //table.unwrap();
            $(_body).addClass('has-table');
        }

        var accordion = $(_body).find('.accordion');

        if (accordion.length) {
            $(_body).addClass('has-accordion');
        }

    });

    //Policy Browse Section: 
    //Show and Hide all policy with button click
    if ($('.accordion').length) {

        // add show all hide all buttons here
        $('.show_hide_buttons').each(function () {

            var name = "";

            if ($(this).attr('data-name'))
                name = $(this).attr('data-name');

            var size = "primary";

            if ($(this).attr('data-size'))
                size = $(this).attr('data-size');

            $(this).append('<a class="gray-btn-' + size + ' show_all_btn desktop-only" href="javascript:void(0);">Show all ' + name + '</a><a class="gray-btn-' + size + ' hide_all_btn desktop-only" href="javascript:void(0);">Hide all ' + name + '</a>');

        });

        $('.show_all_btn').click(function (e) {
            e.preventDefault();
            $('.ui-accordion-content').show();
            $('.ui-accordion-header .ui-accordion-header-icon').removeClass('ui-icon-triangle-1-e').addClass('ui-icon-triangle-1-s');
            $('.ui-accordion-header').addClass("showallheader");
            $('.showallheader').click(function (e) {
                if ($(this).hasClass('showallheader')) {
                    $(this).parent(".accordion").find('.ui-accordion-header').removeClass("showallheader");
                    $(this).parent(".accordion").find('.ui-accordion-header .ui-accordion-header-icon').removeClass('ui-icon-triangle-1-s').addClass('ui-icon-triangle-1-e');
                    var acc_num = $(this).attr("id");
                    acc_num = acc_num.charAt(acc_num.length - 1);
                    acc_num = parseInt(acc_num);
                    $(this).parent(".accordion").find('.ui-accordion-content').hide();
                    $(this).parent(".accordion").accordion({
                        active: false
                    });
                    $(this).parent(".accordion").accordion({
                        active: acc_num
                    });
                }
            });
        });

        $('.hide_all_btn').click(function (e) {
            e.preventDefault();
            $('.ui-accordion-header .ui-accordion-header-icon').removeClass('ui-icon-triangle-1-s').addClass('ui-icon-triangle-1-e');
            $('.ui-accordion-content').hide();
            $('.accordion').accordion({
                active: false
            });
        });

        $('.hide_all_btn').trigger('click');

    } else {
        $('.show_all_btn').hide();
        $('.hide_all_btn').hide();
    }

};

/*
 --------------------
 8. InitCarousels
 --------------------
 */
function InitCarousels() {

    var carousels = $('.carousel-container');

    if (carousels.length > 0 && cssEnabled) {

        // loop through the carousels
        carousels.each(function () {

            var carousel = $(this).find('.carousel');
            var carouselID = carousel.attr('id');

            // add in the controls
            if (!$(this).find(".carousel-controls").length) {

                if (carouselID == "carousel-navigation") {

                } else if (carouselID == "campaign-items") {

                    // if the number of items is greater then visible
                    if (!($('html').hasClass('mobile')) && $(this).find('ul.carousel li').length > 4 || ($('html').hasClass('mobile')) && $(this).find('ul.carousel li').length > 1) {


                        $(this).prepend($('<a class="previous mobile-only" href="#">Previous</a>'));
                        $(this).append($('<a class="next mobile-only" href="#">Next</a>'));

                    }

                    $(this).append($('<div class="carousel-controls"><div class="carousel-pagination"></div></div>'));

                } else {

                    /* default */
                    $(this).append($('<div class="carousel-controls"><a class="previous mobile-only" href="#">previous</a><div class="carousel-pagination desktop-only"></div><a class="next mobile-only" href="#">next</a></div>'));


                    if ($(this).find('ul.carousel li').length > 1) {

                        // if there are more then one slide then attach play and pause buttons
                        $(this).find('.carousel-controls').append(' <a class="play desktop-only" href="#">play</a> <a class="pause desktop-only" href="#">pause</a>');

                    }

                }

            }

            var pagination = $(this).find('.carousel-pagination');

            /* Generic */
            var data = {

                auto: {
                    play: true,
                    delay: 9000,
                    duration: 750
                },
                prev: {
                    key: 'left'
                },
                next: {
                    key: 'right'
                },
                infinite: true,
                circular: false,
                swipe: {
                    onTouch: true
                },
                items: {
                    visible: 1
                },
                pagination: {
                    container: pagination,
                    items: 1
                }

            };

            // carousel specific
            if (carouselID == 'carousel-navigation') {

                // overwrite
                data.auto = false;
                data.infinite = false;
                data.align = 'center';
                data.width = "100%";
                data.items.visible = "variable";
                data.pagination = {};

            } else if (carouselID == 'campaign-items') {

                // overwrite
                data.auto = false;
                data.infinite = false;
                data.align = 'center';
                data.items.visible = 3;
                data.items.start = carousel.find('li.active').first();
                data.items.width = 'variable';
                data.pagination = {};
                data.pagination = pagination;

            }

            // if its mobile
            if ($('html').hasClass('mobile')) {

                data.width = "100%";
                data.responsive = true;
                data.items.visible = 1;

                if (!$('.landing .carousel').length) {
                    data.pagination = {};
                }

            }

            carousel.carouFredSel(data);

            // init buttons
            $(this).find('.play, .pause, .next, .previous').on('click', function (_e) {

                _e.preventDefault();

                if ($(_e.target).hasClass("pause") || $(_e.target).parent().hasClass("pause")) {
                    carousel.trigger('pause');
                } else if ($(_e.target).hasClass("play") || $(_e.target).parent().hasClass("play")) {
                    carousel.trigger('play');
                } else if ($(_e.target).hasClass("previous") || $(_e.target).parent().hasClass("previous")) {
                    carousel.trigger('prev');
                } else if ($(_e.target).hasClass("next") || $(_e.target).parent().hasClass("next")) {
                    carousel.trigger('next');
                }

            });

            // resize the pagination
            var parentWidth = Math.floor(pagination.parent().width() / 2);
            var paginationWidth = Math.floor(pagination.width() / 2);

            var margin = parentWidth - paginationWidth;

            pagination.css("margin-left", margin + "px");
            // For accessibility when CSS is disabled
            pagination.find('a').after(' ');
            pagination.find('a').css('margin-right', '-1px');

        });

    }

};

/*
 --------------------
 9. InitVideo
 --------------------
 */
function InitVideo() {

    // create unique ids
    $('.video-player').each(function () {

        var id = $(this).attr('id');
        var unique_id = id.replace('video-player-', '');

        do {

            $("#" + id).each(function (index) {

                var new_id = Math.random().toString(36).substr(2, 9);

                $(this).attr('id', 'video-player-' + new_id);
                $(this).next("#video-data-" + unique_id).attr('id', "video-data-" + new_id);

            });

        } while ($("#" + id).length);

    });

    /* Player */
    $(".video-data").each(function () {

        var data_id = $(this).attr("id");
        var asset_id = data_id.replace("video-data-", "");

        var captionsArr = [];

        if ($(this).find("span.caption1").length > 0 && $(this).find("span.caption1").text() != "") {
            captionsArr[0] = {
                file: $(this).find("span.caption1").text(),
                label: "English"
            };
        }

        var vid_path = $(this).find("span.file").text();
        var element = "video-player-" + asset_id;

        jwplayer("video-player-" + asset_id).setup({
            'id': "player-id-" + asset_id,
            'width': '100%',
            'height': '100%',
            'fallback': false,
            'modes': [{
                    type: 'flash',
                    src: '/jwplayer/jwplayer.flash.swf'
                },
                {
                    type: 'html5'
                },
                {
                    type: 'download'
                }
            ],
            'autostart': 'false',
            'repeat': 'false',
            ga: {},
            'item': '0', //autostart first video
            playlist: [{
                captions: captionsArr,
                file: $(this).find("span.file").text(),
                image: $(this).find("span.image").text()
            }]

        });

    });

    /*Video transcript */
    $(".video .transcript").hide();

    $('#show_hide_transcript').click(function () {

        var linkText = $('.video .transcript').is(":visible") ? "Show video transcript" : "Hide video transcript";
        $("#show_hide_transcript").text(linkText);
        $('.video .transcript').slideToggle(250);

        return false;

    });

    $('.show_hide_transcript').click(function () {

        var transcript = $(this).parent().find('.transcript');
        var text = transcript.is(":visible") ? "Show video transcript" : "Hide video transcript";
        $(this).text(text);

        transcript.slideToggle(250);

        return false;

    });

};

/*
 --------------------
 10. Page Specific
 --------------------
 */
/*-- 10.1 InitContactUs --*/
function InitContactUs() {

    jQuery.validator.addMethod('claimNo', function (value, element, params) {
        var format = new RegExp(/^[0-9]{2}\/[0-9]{5}$/i);
        return format.test(value) || (!$(element).hasClass("required") && (value === '' || value.indexOf("enter claim number") >= 0));
    }, "Please enter a valid claim number");

    jQuery.validator.addClassRules("claim-no", {
        claimNo: true
    });

    $("#form_email_23269").validate({
        onkeyup: false,
        invalidHandler: function (e, validator) {

            $('.error-wrapper').html("");

            var errors = validator.numberOfInvalids();

            if (errors) {

                $(".error-wrapper").html('<div class="errors"><div class="errors-header"><span></span>Oooops, it looks like there is a mistake on the form.</div><div class="errors-body"><p>Please check the following field(s).</p><ul></ul></div></div>');

                var errorList = validator.errorList;

                for (var i in validator.errorMap) {

                    var label = $("[for=" + i.replace(":", "_") + "]").first();
                    $('.errors ul').append("<li><span data-for=" + label.attr("for") + ">" + label.text().replace("*", "") + "</span></li>");

                }

                $(".errors").show();

            } else {
                $(".errors").hide();
            }

        },
        messages: {
            "q23269:q1": "Please provide your first name",
            "q23269:q2": "Please provide your last name",
            "q23269:q7": "This field is required",
            "q23269:q3": {
                required: "We need your email address to contact you",
                email: "This doesn't look like a correctly filled in email address eg. email@example.com"
            }
        },
        showErrors: function (errorMap, errorList) {
            $('.select-wrapper').removeClass('error');
            for (var i = 0; errorList[i]; i++) {
                var element = this.errorList[i].element;
                this.errorsFor(element).remove();
            }
            this.defaultShowErrors();
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {

            if (element.is("select")) {

                error.insertAfter(element.parent());
                element.parent().addClass('error');

            } else {
                error.insertAfter(element);
            }

        }
    });

};

/*-- 10.2.1 videoCallBack --*/
function videoCallBack() {
    dataLayer.push({
        "event": "jwplayer loaded"
    });
}


/*-- 10.2 InitCampaigns --*/
function InitCampaigns() {

    if ($('.inside-campaigns').length > 0) {

        // get all the links
        var links = $('.sidenavigation > ul > li > a');

        var otherwebsites = '<div class="otherwebsites-mobile mobile-only">' +
            '<div class="center-align clear">' +
            '<form action="//www.tac.vic.gov.au/_design/nested-content/other-websites-redirect" method="get">' +
            '<div class="select-wrapper">' +
            '<span></span>' +
            '<select name="url" title="other websites">';

        links.each(function () {

            otherwebsites += '<option value="' + $(this).attr("href") + '">' + $(this).text() + '</option>';

        });

        otherwebsites += '</select>' +
            '</div>' +
            '<input class="orange-btn-primary" type="submit" name="sumbit" value="Go">' +
            '</form>' +
            '</div>' +
            '</div>';

        $(otherwebsites).insertAfter('.lhs');

        $('.otherwebsites-mobile option:first').attr('selected', true);

        var linkscarousel = '<div class="links-carousel"><div class="carousel-container"><a class="previous" href="#"><span></span>Previous</a><ul id="carousel-navigation" class="carousel">';

        links.each(function () {

            // get the parent
            var parent = $(this).parent();
            var active = "";

            if (parent.hasClass('active'))
                active = "active";

            linkscarousel += '<li class="' + active + '"><a href="' + $(this).attr("href") + '">' + $(this).text() + '</a></li>';

        });

        linkscarousel += '</ul><a class="next" href="#"><span></span>More</a></div></div>';

        $(linkscarousel).insertAfter('.lhs');


        $(".campaign-details-link").on('click', function (_e) {

            _e.preventDefault();

            // change the active 
            $("#campaign-items li").removeClass('active');
            $(this).parent().addClass('active');

        });

        $("#campaign-items li").first().addClass('active');

    }

    /*  Convert links to AJAX */
    $(".campaign-details-link").each(function () {
        $(this).click(
            function (objEvent) {
                $("#campaign-details-container").html("<div class='loading'>Loading details...</div>");
                var jLink = $(this);
                $.ajax({
                    url: jLink.attr("href") + "?SQ_DESIGN_NAME=blank-design&SQ_PAINT_LAYOUT_NAME=ajax-layout",
                    type: "get",
                    dataType: "html",
                    error: function () {
                        $("#campaign-details-container").html("<p>Page Not Found.</p>");
                        window.location = jLink.attr("href");

                    },
                    success: function (strData) {
                        window.location.hash = '#' + jLink.attr("rel");
                        $("#campaign-details-container").html(strData);
                        /* Setup players and tabs on AJAX loaded content */
                        InitVideo();
                        InitTabsAccordions();
                        videoCallBack();
                    }
                });
                // Prevent default click.
                return (false);
            }
        );
    });

    /* Load content (Ex. bookmarks) for URLS using # (hash) ) */
    if (window.location.hash != "") {
        $('#campaign-items a').each(function () {
            if ($(this).attr("rel") == window.location.hash.replace("#", "")) {
                $(this).trigger('click');
                return;
            }
        });
    }

};

/*-- 10.3 InitDocumentsForms --*/
function InitDocumentsForms() {

    /* Section 13. Documents and Forms */
    $(".document-listing").each(function () {
        $(this).find("div").filter(function () {
            return $(this).find("a").first().html().match(/^[a-e]/i);
        }).wrapAll('<div class="link-group" name="A to E" />')

        $(this).find("div").filter(function () {
            return $(this).find("a").first().html().match(/^[f-j]/i);
        }).wrapAll('<div class="link-group" name="F to J"/>');

        $(this).find("div").filter(function () {
            return $(this).find("a").first().html().match(/^[k-o]/i);
        }).wrapAll('<div class="link-group" name="K to O" />');

        $(this).find("div").filter(function () {
            return $(this).find("a").first().html().match(/^[p-t]/i);
        }).wrapAll('<div class="link-group" name="P to T"/>');

        $(this).find("div").filter(function () {
            return $(this).find("a").first().html().match(/^[u-z]/i);
        }).wrapAll('<div class="link-group" name="U to Z" />');

        $(this).find("div").filter(function () {
            return $(this).find("a").first().html().match(/^[\W\d]/i);
        }).wrapAll('<div class="link-group" name="Numbers or Symbols" />');
    });

    $(".document-listing .link-group").each(function () {
        $("<h3>" + $(this).attr("name") + "</h3>").insertBefore($(this));
    });

    /* Tabs driven by class .tabs */
    //$( ".document-listing" ).accordion(); // remove this ?

};

/*-- 10.4 InitEvents --*/
function InitEvents() {

    if ($(".events-data-table").length > 0) {
        var getTabName = function (monthName) {
            switch (monthName) {
                case "Jan":
                case "Feb":
                case "Mar":
                    return "Jan - Mar";
                case "Apr":
                case "May":
                case "Jun":
                    return "Apr - Jun";
                case "Jul":
                case "Aug":
                case "Sep":
                    return "Jul - Sep";
                case "Oct":
                case "Nov":
                case "Dec":
                    return "Oct - Dec";
            }
        }

        var years = [];
        $(".events-data-table tr[title]").each(function () {
            if (jQuery.inArray($(this).attr("title"), years) < 0)
                years.push($(this).attr("title"));
        });

        var tabNames = [];
        $.each(years, function (index, value) {
            $(".events-data-table tr[title=" + value + "]").each(function () {
                var yearLabel = $(this).attr("title");
                var monthLabel = getTabName($(this).attr("class"));
                var tabName = monthLabel + " " + yearLabel;
                if (jQuery.inArray(tabName, tabNames) < 0)
                    tabNames.push(tabName);
            });
        });
        /*Create tabs markup */
        var curTabIndex = 1;
        $.each(tabNames, function (index, currentTabName) {
            /* Limit to four tabs to preserve the layout */
            if (curTabIndex > 4)
                return;
            var targetTabContent = $("#tabs-" + curTabIndex + " tbody").first();
            var targetTabLink = $('#tab-link-' + curTabIndex)
            $('.events-data-table tr').filter(function () {
                var generatedTabName = getTabName($(this).attr("class")) + " " + $(this).attr("title")
                return currentTabName == generatedTabName;
            }).appendTo(targetTabContent);
            targetTabLink.html(currentTabName);
            targetTabLink.parent().removeClass("not-in-use");
            $("#tabs-" + curTabIndex).removeClass("not-in-use");
            curTabIndex++;
        });

        /* Remove not in use markup */
        $(".not-in-use").remove();

        /* Remove original data table */
        $(".events-data-table").remove();

        $('#event-tabs-template').removeClass('hide');

        /*-- Add Table sorting functionality --*/
        $('.basic-sort').dataTable({
            "bPaginate": false,
            "sPaginationType": "full_numbers",
            "bFilter": false,
            "bSearchable": false,
            "bInfo": false,
            "aoColumns": [
                null,
                null,
                {
                    "sType": "date-dd-mmm-yyyy"
                }
            ]
        });

    }

};

/*-- 10.5 InitLocateServiceProvider --*/
function InitLocateServiceProvider() {

    if ($('html').hasClass('mobile')) {

        if ($('#area_list').length === 0) {

            var regions = ["Mallee", "Wimmera", "Northern Country", "North Central", "North East", "East Gippsland", "South West", "Central", "West &amp; South Gippsland"],
                $arealist = $("<div id='area_list' class='mobile-only'><ul></ul></div>");

            for (var i = 0, max = regions.length; i < max; i++) {
                $arealist.find("ul").append("<li><a href='#' title='&quot;" + regions[i] + "&quot;'>" + regions[i] + "</a></li>");
            }

            $arealist.find("ul").append("<span class='divider'>Or search outside of Victoria</span><br /><a href='#' title=''>Australia</a>");

            $("#by_region").append($arealist);

            $('#area_list a').click(function (_e) {

                _e.preventDefault();
                $('#area_list li').removeClass('selected-link');
                $(this).parent().addClass('selected-link');

                var name = $(this).attr('title');
                name.replace("&", "");

                $('#meta_O_orsand').val(name);

            });

        }

    }

    $("#services_filter").prepend('<label class="error" style="margin-bottom: 10px; max-width: 200px; display: none;"><span></span>Please select an speciality</label>');

    $(".service-provider-search a[rel]").click(function () {

        $("#services_filter .error").hide();

        // if its the previous button then clear the selected inputs
        if ($(this).hasClass('nib-left') && $(this).parents('#second_step').length) {
            $('.item-select-box input').prop('checked', false).parent().removeClass('selected');
        }

        /*At least one option must be selected step1 */
        if (!$("#service_type_medical").is(':checked') && !$("#service_type_home").is(':checked'))
            return false

        /*At least one service/speciality has been selected if moving to step3 */
        if ($("input[name='meta_F_orsand']:checked:enabled").length == 0 && $("input[name='meta_T_orsand']:checked:enabled").length == 0 && $(this).attr("href") == "#third_step") {
            $("#services_filter .error").show();
            return false;
        }

        /** Custom screen behaviors **/
        var name = "" + $('#services_filter :checked').val();
        name = name.replace(/"/g, '').replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        });

        /* Custom messages */
        if ($("#service_type_medical").is(':checked')) {

            $("#third_step .step-header h2").html("Select region or post code");
            $("#second_step .step-header h2").html("What area do you want to find practitioners in?");
            $("#selected_service_msg").html("You have chosen <strong>" + name + "</strong>. Now select a region or search via postcode to find a practitioner.");

            $(".select-search").show();

        } else {

            $("#third_step .step-header h2").html("Select region");
            $("#second_step .step-header h2").html("What kind of home services are you looking for?");
            $("#selected_service_msg").html("You have chosen <strong>" + name + "</strong>. Now select the region you live in.");

            $(".select-search").hide();

        }

        /* Reset region and post code when switching option */
        if ($(this).attr("href") == "#by_region") {

            $("#query_post_code").val("!padrenull");
            $(".suburb-post-results").hide();
            $("#suburb_post_keyword").val("");

        } else if ($(this).attr("href") == "#by_post_code") {

            $("#meta_O_orsand").val("");
            $(".selected_region_details").hide();

        }

        /** Perform navigation **/

        /*Hide all elements from same group */
        $("." + $(this).attr("rel")).hide();
        $("a[rel=" + $(this).attr("rel") + "]").removeClass("active");

        /*Show target element */
        $($(this).attr("href")).show();

        if (mobile)
            window.location.hash = $(this).attr("href");

        $(this).addClass("active");

        return false;

    });

    /* Conditional content of second screen */
    if (!$("#service_type_medical").is(':checked')) {
        $('div.optionA').hide();
    }
    if (!$("#service_type_home").is(':checked')) {
        $('div.optionB').hide();
    }

    /* Select Check boxes */
    $('.box-field, .item-select-box').click(function (_e) {

        _e.preventDefault();

        var type = ".box-field";

        if ($(this).hasClass('item-select-box')) {
            type = ".item-select-box";
            $("#services_filter .error").hide();
        }

        // remove all the selected states
        $(type).removeClass('selected');
        $(type).find('input').prop('checked', false);

        // make this one selected
        $(this).addClass('selected');
        $(this).find('input').prop('checked', true).trigger('change');

    });

    /* Invisible elements are unchecked to prevent them to be send on the form */
    $("#service_type_medical").change(function (_e) {
        $('div.optionA').show();
        $('div.optionB').hide();
    });

    $("#service_type_home").change(function (_e) {
        $('div.optionB').show();
        $('div.optionA').hide();
    });

    /*Search suburb form */
    $("#suburb_post_submit").click(function () {

        var funnelbackBaseURL = "http://search.tac.vic.gov.au/s/search.json";
        var searchKeyword = $("#suburb_post_keyword").val();
        var resultsDiv = $("#suburb-post-results");
        resultsDiv.html("searching for suburbs...");
        $("#query_post_code").val(searchKeyword);
        $.ajax({
            type: 'GET',
            contentType: "application/json; charset=utf-8",
            dataType: "jsonp",
            data: {
                collection: "tac-postcodes",
                query: searchKeyword
            },
            url: funnelbackBaseURL,
            success: function (data) {
                if (data.response.resultPacket.results.length > 0) {
                    resultsDiv.html("You are searching for<br>");
                    $.each(data.response.resultPacket.results, function (key, val) {
                        var link = $("<strong>" + val.metaData["A"] + " " + val.metaData["B"] + "</strong><br>");
                        resultsDiv.append(link);
                        $("#query_post_code").val(val.metaData["A"].trim());
                    });
                } else {
                    resultsDiv.html("No results where found for " + searchKeyword + "");
                }

                resultsDiv.parent().show();

            },
            error: function (data, textStatus, jqXHR) {
                resultsDiv.html("<strong>Search service unavailable</strong>");
            }
        });
        return false;
    });

    // populate screen
    var query = function () {

        var query_string = {};
        var query = window.location.search.substring(1);
        var vars = query.split("&");

        for (var i = 0; i < vars.length; i++) {

            var pair = vars[i].split("=");

            if (typeof query_string[pair[0]] === "undefined") {
                query_string[pair[0]] = pair[1];
            } else if (typeof query_string[pair[0]] === "string") {
                var arr = [query_string[pair[0]], pair[1]];
                query_string[pair[0]] = arr;
            } else {
                query_string[pair[0]].push(pair[1]);
            }

        }

        return query_string;

    }();

    if (typeof query.service !== "undefined") {

        var $service = $("#first_step label:contains(" + decodeURI(query.service) + ")").parents(".box-field");

        if ($service.length > 0) {

            $service.click();
            $(".controls:visible:first a:contains(Next)").click();

            if (typeof query.provider !== "undefined") {

                $provider = $("#second_step label:contains(" + decodeURI(query.provider) + ")").parents(".item-select-box");

                if ($provider.length > 0) {

                    $provider.click();
                    $(".controls:visible:first a:contains(Next)").click();

                }

            }

        }

    }

};

if ($("#inTAC-Home")) {
    getAjaxContent('/_design/nested-content/home/intac/_nocache', "#inTAC-Home");
}






/*
 --------------------
 11. InitFunnelback
 --------------------
 */
function InitFunnelback() {

    //If javascript is enabled, clicking on a service provider link should only cause the marker to bounce. 
    //The following prevents the default from occurring
    $('.service-provider-search .result-service-provider a').click(function (_e) {
        _e.preventDefault();
    });

};

/*
 --------------------
 12. Functions
 --------------------
 */
function checkWidth(init) {
    /*If browser resized, check width again */
    if ($(window).width() < 480) {
        $('html').addClass('mobile');
    } else {
        if (!init)
            $('html').removeClass('mobile');
    }
}

function getAjaxContent(whaturl, whatlocation) {
    /* Use AJAX to pull in content */
    $(whatlocation).load(whaturl);
}




/*
 --------------------
 13. Native Prototype Extension
 --------------------
 */
/* add trim function if it doesn't exist */
if (typeof String.prototype.trim !== 'function') {
    String.prototype.trim = function () {
        return this.replace(/^\s+|\s+$/g, '');
    }
}

$(document).ready(function () {

    /*
     ...........................................
     .
     Generating most visited pages using cookies
     .
     ...........................................
     */
    //function to create sub menu list in corresponding section
    function createMenuItemsPerSection(data, append_links_to) {
        var hasErrors = data.filter(function (item) {
            return item.error;
        }).length ? true : false;
        //running the loop over array of menu items to
        for (var index = 0; index < menu_items.length; index += 1) {
            var $menu_ul = $('<ul></ul>');
            if (typeof (append_links_to) === 'undefined') {
                var $current_menu_place_holder = $(main_nav_wrapper + ' a[href$=' + menu_items[index] + ']').parent('li').find(place_holder_for_menu_inside_li);
            } else {
                var $current_menu_place_holder = $(append_links_to);
            }

            //If the asset is deleted JSAPI will throw an error so delete the current section cookie
            if (data.error || hasErrors) {
                docCookies.removeItem(menu_items[index] + '_global_ranking', '/');
            } else {
                for (var items in data) {

                    //loop through each asset id in stored variable and find a match in returned ojbect
                    for (var each_item_in_stored_top_assets in top_ranked_id[index]) {

                        if (top_ranked_id[index][each_item_in_stored_top_assets] === data[items].id) {
                            $('<li><a href=' + data[items].web_path + ' class="ga_mostvisited">' + data[items].short_name + '</a></li>').appendTo($menu_ul);
                        } // if ending
                    } //loop through asset ids in variable
                } // loop through all items in data
                if ($menu_ul.find('li').length > 0) {
                    var $new_menu = $(most_visited_wrapper).append($menu_ul);
                    $new_menu.appendTo($current_menu_place_holder);
                } //end if
            } //end else
        }
    } // end function creating menus

    //create a single menu for all sections
    function createSingleMenu(data, append_links_to, most_visited_wrapper, section_name) {
        //running the loop over array of menu items to
        var $menu_ul = $('<ul></ul>');
        var $current_menu_place_holder = $(append_links_to);
        var hasErrors = data.filter(function (item) {
            return item.error;
        }).length ? true : false;

        //If the asset is deleted JSAPI will throw an error so delete the current section cookie
        if (data.error || hasErrors) {
            docCookies.removeItem(section_name, '/');
            $current_menu_place_holder.html('No items available.');
        } else {
            for (var items in data) {
                $('<li><a href=' + data[items].web_path + ' class="ga_mostvisited">' + data[items].short_name + '</a></li>').appendTo($menu_ul);
            } // loop through all items in data
            if ($menu_ul.find('li').length) {
                var $new_menu = $(most_visited_wrapper).append($menu_ul);
                $new_menu.appendTo($current_menu_place_holder);
            } //end if
        } //end else

    } // end function creating menus

    function setCookiesPerSection(category_name, page_asset_id) {
        var section_name = category_name;
        var current_asset_id = page_asset_id;
        var pages = [];
        var cookie_exist = docCookies.hasItem(section_name);
        var cookie_value = docCookies.getItem(section_name);
        //setting up cookies
        var setCookie = function (section_name, pages) {
            docCookies.setItem(section_name, pages, Infinity, "/");
        }

        //if cookie exists
        if (cookie_exist) {
            pages = cookie_value.split(',');
            for (var index in pages) {
                var asset_entry_explode = pages[index].split(":");
                var asset_entry_id = asset_entry_explode[0];
                var asset_entry_rank = asset_entry_explode[1];
                if (current_asset_id === asset_entry_id) {
                    pages.splice(index, 1);
                    var increment_rank = parseInt(asset_entry_rank) + 1;
                    pages.push(current_asset_id + ":" + increment_rank);
                    setCookie(section_name, pages);
                    break;
                }

            } //end for each item in array  

            if (current_asset_id !== asset_entry_id) {
                //if item doesn't exists add the id           
                pages.push(current_asset_id + ":1");
                setCookie(section_name, pages);
            }

        } else { //if cookie doesn't exists 
            pages.push(current_asset_id + ":1");
            setCookie(section_name, pages);
        }
    } // end function setCookiesPerSection


    // getting cookie name and returning sorted array of top ranked asset ids
    function get_top_ranking_asset_ids(cookie_value, menu_required_length) {
        var asset_id_raw = {};

        $.each(cookie_value.split(","), function (index, val) {
            var value_split = val.split(":");
            asset_id_raw[value_split[0]] = value_split[1];
        });
        var sorted_array = sortObject(asset_id_raw);
        var slice_start_position = sorted_array.length - menu_required_length;
        //if array has more objects than required most visited items then slice it
        if (slice_start_position > 0) {
            sorted_array.splice(menu_required_length, slice_start_position);
        }

        //return sorted objects in array
        var sorted_new_array = [];
        for (var object_key in sorted_array) {
            sorted_new_array.push(sorted_array[object_key].key);
        }
        return sorted_new_array;

    } // end get_top_ranking

    //apply full-width class to each mega menu with most visited pages container
    function applyFullWidthClass() {
        if ($(mega_menu_container).length) {
            //loop through each megamenu section
            $(mega_menu_container).each(function () {
                //If this mega menu contains most visited container then add full width class to mega menu
                if ($(this).find('div.most-visited').length) {

                    if ($(this).find('.container > div').length > 2) {
                        $(this).addClass("mega_menu_full_width");
                    }

                    if (desktop) {

                        $('.megamenu .full-height').css("height", "");

                        // add offscreen to any elements that are display none that need full height
                        $('.megamenu').css({
                            'display': 'block',
                            'left': '-9999px'
                        });

                        // set the heights of all divs with full height class
                        $('.megamenu  .full-height').height(function () {
                            return $(this).outerHeight();
                        });

                        $('.megamenu').css({
                            'display': '',
                            'left': ''
                        });

                    }

                }
            });
        }
    } // end apply full width class

    //sorting objects by the rank and retuning sorted array
    function sortObject(obj) {
        var arr = [];
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                arr.push({
                    'key': prop,
                    'value': obj[prop]
                });
            }
        }
        arr.sort(function (a, b) {
            return b.value - a.value;
        });
        return arr; // returns array
    }

    // requesting asset details from JSAPI
    function load_asset_details(asset_ids, append_links_to, callback) {

        // Loading page info via JSAPI
        var api = new Squiz_Matrix_API({
            key: '2100061516'
        });

        var batchFunctions = {};
        for (var i = 0, l = asset_ids.length; i < l; i += 1) {
            batchFunctions[i] = {
                "function": "getGeneral",
                "args": {
                    "asset_id": asset_ids[i],
                    "get_attributes": 0
                },
                "blocking": 0
            }
        }

        api.batchRequest({
            "functions": batchFunctions,
            "dataCallback": function (data) {
                callback(data);
            }
        });

    } // requesting asset details    

    //Removing extra popular and feature divs from each mega menu
    //Please keep all the data-id attributes in design
    $('.navigation li[class^="menu"]').each(function () {
        var current_data_id = $(this).attr('data-id');
        $(this).find('div.popular[data-id!=' + current_data_id + ']').remove();
        $(this).find('div.feature[data-id!=' + current_data_id + ']').remove();
    });

    // Adding page rank value to cookie on each page load (in specified sections)
    //Also generating links for each section and adding them to required location
    //On funnel back Search pages do not submit JSAPI requests
    if ($('#wrapper .navigation').length && window.location.pathname.indexOf('search.html') < 0) {

        var menu_items = ['about-the-tac', 'road-safety', 'clients', 'claims', 'providers']; //Add remove menu webpaths here (paths to sections need to be correct)
        var menu_required_length = 6;
        var most_visited_wrapper = '<div class="most-visited"><span class="heading">Your most visited pages</span></div>';
        var main_nav_wrapper = '#wrapper .navigation';
        var mega_menu_container = '.navigation .megamenu'; // to apply full width classes
        var current_webpath = location.pathname;
        var current_page_asset_id = $(main_nav_wrapper).attr('data-currentid');
        var place_holder_for_menu_inside_li = 'div.megamenu div.container';
        var top_ranked_id = [];
        var all_asset_ids_combined = [];
        var append_links_to;

        for (var menu_index = 0; menu_index < menu_items.length; menu_index += 1) {
            if (current_webpath.indexOf(menu_items[menu_index]) > 0) {
                setCookiesPerSection(menu_items[menu_index] + '_global_ranking', current_page_asset_id);
            }
            //checking if cookie exists then find the top 9 ranked items
            if (docCookies.hasItem(menu_items[menu_index] + '_global_ranking')) {

                var cookie_value = docCookies.getItem(menu_items[menu_index] + '_global_ranking');
                var top_ranked_asset_ids = get_top_ranking_asset_ids(cookie_value, menu_required_length);
                //storing all sections ids in seperate variable; to be used later
                top_ranked_id[menu_index] = top_ranked_asset_ids;

                //pushing all top ranked id to one array
                for (var top_ranked_index in top_ranked_asset_ids) {
                    all_asset_ids_combined.push(top_ranked_asset_ids[top_ranked_index]);
                }
            }
        } // end loop

        //loading details of all top ranked ids in one call
        load_asset_details(all_asset_ids_combined, append_links_to, function (data) {
            createMenuItemsPerSection(data, append_links_to);
            applyFullWidthClass();
        });


    } // End if #wrapper length, if it is a Funnelback search page

    //check for other requests for pages history 
    checkOtherPageHistoryRequest();

    function pageHistoryPerSection(section_name, current_page_asset_id, append_links_to, menu_wrapper, max_cookie_items, menu_required_length) {
        var tmp_cookie_value_array = [];
        var asset_ids_to_process = [];
        //if cookie doesn't exists we need to create it and push the value
        if (!docCookies.hasItem(section_name)) {
            docCookies.setItem(section_name, current_page_asset_id, Infinity, "/");
        }
        //If cookies exists
        else if (docCookies.hasItem(section_name)) {
            var cookie_value = docCookies.getItem(section_name);
            tmp_cookie_value_array = cookie_value.split(",");
            //loop through the array and remove current asset id
            $.each(tmp_cookie_value_array, function (index, val) {
                if (current_page_asset_id === tmp_cookie_value_array[index]) {
                    tmp_cookie_value_array.splice(index, 1);
                }

            });
            //Add current asset id at the top
            tmp_cookie_value_array.push(current_page_asset_id);

            //check if cookie value exceed number of allowed items, if yes then cut the array

            var cookie_value_slice_position = tmp_cookie_value_array.length - max_cookie_items;
            //if array has more objects than required most visited items then slice it
            if (cookie_value_slice_position > 0) {
                tmp_cookie_value_array.splice(max_cookie_items, cookie_value_slice_position);
            }
            var newTempCookieValue = tmp_cookie_value_array.join(',');
            //storing new cookie value to this cookie
            docCookies.setItem(section_name, newTempCookieValue, Infinity, "/");
        } //end elseif


        asset_ids_to_process = docCookies.getItem(section_name).split(",").reverse();

        var slice_start_position = asset_ids_to_process.length - menu_required_length;
        //if array has more objects than required most visited items then slice it
        if (slice_start_position > 0) {
            asset_ids_to_process.splice(menu_required_length, slice_start_position);
        }

        //loading details of all top ranked ids in one call
        load_asset_details(asset_ids_to_process, append_links_to, function (data) {
            createSingleMenu(data, append_links_to, menu_wrapper, section_name);
        });
        //removing navigation
    }

    //return first three chars for month index
    function getMonthName(currentMonth) {
        if (currentMonth == 1) {
            currentMonth = 'Jan';
        } else if (currentMonth == 2) {
            currentMonth = 'Feb';
        } else if (currentMonth == 3) {
            currentMonth = 'Mar';
        } else if (currentMonth == 4) {
            currentMonth = 'Apr';
        } else if (currentMonth == 5) {
            currentMonth = 'May';
        } else if (currentMonth == 6) {
            currentMonth = 'Jun';
        } else if (currentMonth == 7) {
            currentMonth = 'Jul';
        } else if (currentMonth == 8) {
            currentMonth = 'Aug';
        } else if (currentMonth == 9) {
            currentMonth = 'Sep';
        } else if (currentMonth == 10) {
            currentMonth = 'Oct';
        } else if (currentMonth == 11) {
            currentMonth = 'Nov';
        } else if (currentMonth == 12) {
            currentMonth = 'Dec';
        }
        return currentMonth;
    } // get month  

    //declaring variables for Date collection was updated
    var fatalitiesUpdatedDate;
    var fatalitiesUpdatedMonth;
    var fatalitiesUpdatedYear;

    // Function to call matrix REST asset that loads tolls from Funnelback collection
    function getToll(funnelbackURL, callback) {
        var toolCount;
        $.ajax({
            url: funnelbackURL
        }).done(function (data) {
            // SDVIC-433: accept either parsed or unparsed response
            var returnedObj = data;
            if (typeof data !== "object") {
                returnedObj = JSON.parse(data);
            }
            var tollCount = returnedObj.response.resultPacket.resultsSummary.fullyMatching;
            fatalitiesUpdatedDate = returnedObj.response.customData["fatalities-dates"]["date-end-day"];
            fatalitiesUpdatedMonth = returnedObj.response.customData["fatalities-dates"]["date-end-month"];
            fatalitiesUpdatedYear = returnedObj.response.customData["fatalities-dates"]["date-end-year"];
            $('.road-toll .no-graph').hide();
            $('.road-toll .graphs').show();
            callback(tollCount);
        });
    } //get Toll

    currentMonth = getMonthName(currentMonth);

    //Getting bigger number from two graphs
    //applying class to graph with higher toll 
    function higherTollClass() {
        var fatalities_current_year_toll = parseInt($('.fatalities_current_year .toll_count').text());
        var fatalities_past_year_toll = parseInt($('.fatalities_past_year .toll_count').text());
        var higher_toll = Math.max(fatalities_current_year_toll, fatalities_past_year_toll);
        $('.toll_count').each(function () {
            if ($(this).text() == higher_toll) {
                $(this).parent().addClass('higher_fatalities');
            }
        });
    }

    function checkOtherPageHistoryRequest() {
        //Policy sections History
        // Adding page rank value to cookie for Policy section
        if (!(window.location.pathname.indexOf('what-tac-pays-for') < 1)) {
            var section_name = 'what-the-tac-pays-for';
            var menu_wrapper = '<div class="most-visited"><span class="heading">Your page history in this section</span></div>';
            var main_nav_wrapper = '#wrapper .navigation';
            var current_webpath = location.pathname;
            var current_page_asset_id = $(main_nav_wrapper).attr('data-currentid');
            var max_cookie_items = 50; //Keep this value biger than menu length ie: > 30
            var menu_required_length = 10;
            var append_links_to = '.contentwrapper .lhs';
            var nav_wrapper = '.sidenavigation';

            //For policy pages extende the menu length to 30
            if ($('.policy_page').length) {
                menu_required_length = 30;
            }

            //Call to function to set cookies for this section, create links and append to desired section
            pageHistoryPerSection(section_name, current_page_asset_id, append_links_to, menu_wrapper, max_cookie_items, menu_required_length);

            //Removing menu if is is policy details page
            if ($('.policy_page').length) {
                //$(nav_wrapper).remove();
            }


        } // End if path is for what tac pays for
        else if (!(window.location.pathname.indexOf('fees-and-policies') < 1)) {
            var urlpath = window.location.pathname;
            var section_name = 'fees-and-policies';
            var menu_wrapper = '<div class="most-visited"><span class="heading">Your page history in this section</span></div>';
            var current_webpath = location.pathname;
            var current_page_asset_id = $('#wrapper .navigation').attr('data-currentid');
            var max_cookie_items = 50; //Keep this value biger than menu length ie: > 30
            var menu_required_length = 10;
            var append_links_to = '.contentwrapper .lhs';
            var nav_wrapper = '.sidenavigation';


            //For policy pages extende the menu length to 30
            if ($('.policy_page').length) {
                menu_required_length = 30;
            }

            //Call to function to set cookies for this section, create links and append to desired section
            pageHistoryPerSection(section_name, current_page_asset_id, append_links_to, menu_wrapper, max_cookie_items, menu_required_length);

            //Removing menu if is is policy details page
            if ($('.policy_page').length) {
                //$(nav_wrapper).remove();
            }

        } // End if path is fees-and-policies
        // Home Page: Popular Pages
        else if ($('.most-visited.home').length) {
            //var menu_items = ['fees-and-policies']; //Add remove menu webpaths here (paths to sections need to be correct)
            var menu_required_length = 8;
            var most_visited_wrapper = '<div></div>';
            var mega_menu_container = '.navigation .megamenu'; // to apply full width classes
            var top_ranked_id = [];
            var current_webpath = location.pathname;
            var current_page_asset_id = $('#wrapper .navigation').attr('data-currentid');
            var all_asset_ids_combined = [];
            var append_links_to = '.most-visited.home .body';
            var section_name = '';

            var cookies_values_for_home = "";

            // Popular pages cookies are available for this domain
            if (document.cookie && document.cookie != '') {
                var split = document.cookie.split(';');
                for (var i = 0; i < split.length; i++) {
                    var name_value = split[i].split("=");
                    name_value[0] = name_value[0].replace(/^ /, '');
                    // Search cookie names suffixed with "_globals_ranking" for popular pages data
                    if (name_value[0].indexOf('_global_ranking') !== -1) {
                        if (cookies_values_for_home === "") {
                            cookies_values_for_home = cookies_values_for_home.concat(decodeURIComponent(name_value[1]));
                        } else {
                            cookies_values_for_home = cookies_values_for_home.concat(',' + decodeURIComponent(name_value[1]));
                        }
                    }
                }
            }

            // Section IDs
            var top_ranked_asset_ids = get_top_ranking_asset_ids(cookies_values_for_home, menu_required_length);

            // Remove navigation
            $(append_links_to).find('ul').remove();

            // Render popular pages list
            load_asset_details(top_ranked_asset_ids, append_links_to, function (data) {
                createSingleMenu(data, append_links_to, most_visited_wrapper, section_name);
            });
        }

    } //checkOtherPageHistoryRequest

    //return first three chars for month index
    function getMonthName(currentMonth) {
        if (currentMonth == 1) {
            currentMonth = 'Jan';
        } else if (currentMonth == 2) {
            currentMonth = 'Feb';
        } else if (currentMonth == 3) {
            currentMonth = 'Mar';
        } else if (currentMonth == 4) {
            currentMonth = 'Apr';
        } else if (currentMonth == 5) {
            currentMonth = 'May';
        } else if (currentMonth == 6) {
            currentMonth = 'Jun';
        } else if (currentMonth == 7) {
            currentMonth = 'Jul';
        } else if (currentMonth == 8) {
            currentMonth = 'Aug';
        } else if (currentMonth == 9) {
            currentMonth = 'Sep';
        } else if (currentMonth == 10) {
            currentMonth = 'Oct';
        } else if (currentMonth == 11) {
            currentMonth = 'Nov';
        } else if (currentMonth == 12) {
            currentMonth = 'Dec';
        }
        return currentMonth;
    } // get month 

    //declaring variables for Date collection was updated
    var fatalitiesUpdatedDate;
    var fatalitiesUpdatedMonth;
    var fatalitiesUpdatedYear;

    // Function to call matrix REST asset that loads tolls from Funnelback collection
    function getToll(funnelbackURL, callback) {
        var toolCount;
        $.ajax({
            url: funnelbackURL
        }).done(function (data) {
            // SDVIC-433: accept either parsed or unparsed response
            var returnedObj = data;
            if (typeof data !== "object") {
                returnedObj = JSON.parse(data);
            }
            var tollCount = returnedObj.response.resultPacket.resultsSummary.fullyMatching;
            fatalitiesUpdatedDate = returnedObj.response.customData["fatalities-dates"]["date-end-day"];
            fatalitiesUpdatedMonth = returnedObj.response.customData["fatalities-dates"]["date-end-month"];
            fatalitiesUpdatedYear = returnedObj.response.customData["fatalities-dates"]["date-end-year"];
            $('.road-toll .no-graph').hide();
            $('.road-toll .graphs').show();
            callback(tollCount);
        });
    } //get Toll

    //Getting bigger number from two graphs
    //applying class to graph with higher toll 
    function higherTollClass() {
        var fatalities_current_year_toll = parseInt($('.fatalities_current_year .toll_count').text());
        var fatalities_past_year_toll = parseInt($('.fatalities_past_year .toll_count').text());
        var higher_toll = Math.max(fatalities_current_year_toll, fatalities_past_year_toll);
        $('.toll_count').each(function () {
            if ($(this).text() == higher_toll) {
                $(this).parent().addClass('higher_fatalities');
            }
        });
    }

    /*
     Toll Graphs on home page
     */
    if ($('.road-toll').length) {
        var date = new Date();
        var currentDate = date.getDate();
        var currentMonth = date.getMonth() + 1;
        var currentYear = date.getFullYear();
        var pastYear = currentYear - 1;
        var tollDataURL = $('.road-toll').attr('data-tollURL');
        var fatalitiesCurrentYear = tollDataURL +
            '?meta_d3day=1&meta_d3month=Jan&meta_d3year=' + currentYear + '&meta_d4day=&meta_d4month=&meta_d4year=&' +
            'clive=tac-fatalities-xml&' + 'collection=tac-xml-meta&form=tac-web&query=%21null';


        currentMonth = getMonthName(currentMonth);

        //Load toll for current year
        getToll(fatalitiesCurrentYear, function (tollCount) {
            $('.road-toll .fatalities_current_year').show().prepend('<span class="toll_count">' + tollCount + '</span> ').append(' <span>' + currentYear + '</span>');

            //if update collection value is not null make it past year date
            if (fatalitiesUpdatedDate && fatalitiesUpdatedMonth != null && fatalitiesUpdatedYear) {
                currentDate = fatalitiesUpdatedDate;
                currentMonth = getMonthName(fatalitiesUpdatedMonth + 1);
                pastYear = fatalitiesUpdatedYear - 1;
            }

            var fatalitiesPastYear = tollDataURL +
                '?meta_d3day=1&meta_d3month=Jan&meta_d3year=' + pastYear + '&meta_d4day=' + currentDate + '&meta_d4month=' + currentMonth + '&meta_d4year=' + pastYear + '&' +
                'clive=tac-fatalities-xml&' +
                'collection=tac-xml-meta&form=tac-web&query=%21null';

            //Load toll for last year on date collection was updated
            getToll(fatalitiesPastYear, function (tollCount) {
                $('.road-toll .fatalities_past_year').show().prepend('<span class="toll_count">' + tollCount + '</span> ').append(' <span>' + pastYear + '</span>');
                higherTollClass();
            });
        }); //getToll ... current year

    } //if toll section exists


    /*
     Fees and Policies section
     Browse Policy
     Re-grouping A-Z listing in TAC Defined groups
     */
    if ($('#wrapper .desktop-only div.ungrouped').length) {
        $('#wrapper .desktop-only div.ungrouped > h4').each(function () {
            var currentHeadingRange = $(this).text();
            var currentHeading = $(this);
            var currentContent = $(this).nextUntil("h4");
            $('.desktop-only .accordion_content').each(function () {
                //Check if current heading is less or equal than heading groups Max
                if (currentHeadingRange <= $(this).attr('data-max')) {
                    currentHeading.appendTo($(this));
                    currentContent.appendTo($(this));
                    return false;
                } //end if 
            });
        });
        $('.grouped').show();
    } //end if .ungrouped container exists 


    if ($('#wrapper .mobile-only div.ungrouped').length) {
        $('#wrapper .mobile-only div.ungrouped > h4').each(function () {
            var currentHeadingRange = $(this).text();
            var currentHeading = $(this);
            var currentContent = $(this).nextUntil("h4");
            $('.mobile-only .accordion_content').each(function () {
                //Check if current heading is less or equal than heading groups Max
                if (currentHeadingRange <= $(this).attr('data-max')) {
                    currentHeading.appendTo($(this));
                    currentContent.appendTo($(this));
                    return false;
                } //end if 
            });
        });
        $('.grouped').show();
    } //end if .ungrouped container exists 



    //Policy Main Landing page
    //hiding empty link printed by Matrix
    if ($('ul.policy_grouping').length) {
        $('ul.policy_grouping li a:empty').parent().remove();
    }


    //Policy Details Page only
    //Hide paragraphs after 200 words (only limited to paragraphs )
    if ($('.policy_details').length) {
        var textContainer = '.policy_details';
        var wordCount = 0;
        var maxWords = 200;
        $(textContainer).find(' p, table, :header, ul, ol').each(function (index, paragraph) {
            var count = $(this).text().split(' ').length;
            var directChild = $(this).parent().parent().is('.policy_details_full');

            wordCount = wordCount + count;
            //if it is direct child of policy details
            if (directChild) {
                if (wordCount >= maxWords) {
                    $(this).nextAll().hide();
                    return false;
                }
            } // End if 
        });

        $(textContainer).append('<div class="read-more"><a href="javascript:void(0);" class="ga_policy_show_more"><span></span>Read more of this policy</a></div>');

        $('.ga_policy_show_more').click(function () {
            $(this).parent().parent().find('*').show();
            $(this).parent().hide();
        });

    } //end if

    //Policy Browse Section: 
    //Show and Hide all policy with button click
    if ($('.browse_policies').length) {
        $('.show_all_policies').show().click(function (e) {
            e.preventDefault();
            $('.ui-accordion-content').show();
        });
        $('.hide_all_policies').show().click(function (e) {
            e.preventDefault();
            $('.ui-accordion-content').hide();
        });
    } //end if


    //if it is a policy details page
    //Insert a back to policies link if user navigated from policy category listing
    if ($('.policy_page').length) {
        var urlParams = window.location.search;
        if (!(urlParams.indexOf('return-url') < 1)) {
            returnURL = urlParams.split('=');
            $('<div class="back_to_policies"><a class="back" href="' + returnURL[1] + '">Return to Policies</a></div>').insertAfter('ul.tabs-nav');
        } //end if
    } //end if it is policy page

    //Policy Tabbed page Desktop & Mobile
    //If search is performed navigate back to search tab
    if ($('#policy_search_results').length) {
        var urlParams = window.location.search;
        if (!urlParams.indexOf('queries_policy_query') < 1) {
            $('#ui-id-3').click();
            $('#ui-accordion-2-header-2').click();

            if ($("#ui-accordion-2-header-2:visible").length > 0) {
                $(document).scrollTop($("#ui-accordion-2-header-2").offset().top - 120);
            } else {
                $(document).scrollTop($("#policy_search_results").offset().top - 120);
            }
        } //end if
    }

    //Global Pagination
    // If pagination has only one child hide it
    if ($("#wrapper .pagination").length) {

        $("#wrapper .pagination").each(function () {
            if ($(this).find('span.pages a').length === 0) {
                $(this).remove();
            }
        });
        //remove Pagination next and previous button if they are empty
        $('#wrapper .pagination a.next, #wrapper .pagination a.previous').each(function () {
            if ($(this).attr('href').indexOf('http') === -1) {
                $(this).remove();
            }
        });

        //If Pagination contains more than 1 item
        //Hide items on each side to print maximum of 10 items 
        if ($('.pagination .pages a').length >= 10) {
            var currentIndex = $('.pagination .current').index();
            var nextFive = currentIndex + 4;
            var previousFive = currentIndex - 4;
            $('.pagination .pages a:gt(' + nextFive + ')').remove();
            $('.pagination .pages a:lt(' + previousFive + ')').remove();
        } // hide more than 10 items

    } //Global pagination

    //Disable click event on disabled  tab links
    if ($('.policy_page').length) {
        $('li.disabled a').unbind().attr('href', '#');
    }

    //If Locate a health/service provider exists on the page
    if ($('.service-provider-search #by_region').length) {
        $('#interactive_victoria_map area').on({
            click: function () {
                var imageIndex = $("#interactive_victoria_map area").index(this);
                var current_img = $(".map_hovers img:eq(" + imageIndex + ")");
                var searchText = $(".map_hovers img:eq(" + imageIndex + ")").attr('alt');
                $(".map_hovers img").hide();
                current_img.show();
                $("#interactive_image_container").addClass('map-selected');
                $('.selected_region_details .region_value').html(searchText);

                searchText = searchText.replace("& ", "");
                $('#meta_O_orsand').val('"' + searchText + '"');

                $('.selected_region_details').show();
                if ($(".austrlian_map").hasClass('map-selected')) {
                    $('.austrlian_map').removeClass('map-selected');
                }

            },
            mouseenter: function () {
                var imageIndex = $("#interactive_victoria_map area").index(this);
                var current_img = $(".map_hovers img:eq(" + imageIndex + ")");
                if (!$("#interactive_image_container").hasClass('map-selected')) {
                    current_img.show();
                }
            },
            mouseleave: function () {
                var imageIndex = $("#interactive_victoria_map area").index(this);
                var current_img = $(".map_hovers img:eq(" + imageIndex + ")");
                if (!$("#interactive_image_container").hasClass('map-selected')) {
                    current_img.hide();
                }
            },
            focusin: function () {
                var imageIndex = $("#interactive_victoria_map area").index(this);
                var current_img = $(".map_hovers img:eq(" + imageIndex + ")");
                if (!$("#interactive_image_container").hasClass('map-selected')) {
                    current_img.show();
                }
            },
            focusout: function () {
                var imageIndex = $("#interactive_victoria_map area").index(this);
                var current_img = $(".map_hovers img:eq(" + imageIndex + ")");
                if (!$("#interactive_image_container").hasClass('map-selected')) {
                    current_img.hide();
                }
            }
        });

        $('.austrlian_map').click(function (e) {
            e.preventDefault();
            var searchText = $(this).attr('title');
            $(this).addClass('map-selected');
            $('#meta_O_orsand').val('');
            $('.selected_region_details .region_value').html(searchText);
            $('.selected_region_details').show();
            if ($("#interactive_image_container").hasClass('map-selected')) {
                $("#interactive_image_container").removeClass('map-selected');
                $(".map_hovers img").hide();
            }
        });
    } //end if #by_region exists on the page

    //AJAX Loading results with pagination clicks
    function loadResultsPaginationLinks() {
        var location_search_form = $('.service-provider-search form');
        $('.service-provider-search .pagination a').click(function (e) {
            e.preventDefault();
            searchQuery = $(this).attr('href').replace('search.html', '');
            $.ajax({
                type: "GET",
                url: location_search_form.attr('action') + searchQuery,
                success: function (data) {
                    $('#locate_search_results_content').html('<div class="locate_search_results_body">' + $(data).find(".content .col4").html() + '</div>');
                    $('#fb-queryform, a[alt="Start over"]').remove();
                    loadResultsPaginationLinks();

                    var $mapCanvas = $('#map_canvas');
                    if ($mapCanvas.length) {
                        //initiate google maps on load
                        $('#map_canvas').addClass('googlemap');
                        //initiate the map
                        fbMap.mapInit();
                    }
                }
            });
        });
    } //loadResultsPaginationLinks

    // Locate services for policy details page tabs
    if ($('#tabs-6 .service-provider-search').length) {
        var services = [];
        var step_1_container = '#locate_first_step';
        var step_2_container = '#locate_second_step';

        var services_types = $('#location_services_filter .services_types');
        services = $('.services_types').attr('data-services').split(';');
        //if only one service is selected remove step 1
        if (services.length === 1) {
            //hide step 1
            $(step_1_container).hide();
            $(step_2_container + ' .controls a[href="' + step_1_container + '"]').hide();
        } else {
            //clearing the service types input if there are more than one option
            services_types.find('input.service_type').val('');

            //hide step 2
            $(step_2_container).hide();
            //loop through each serice split by ; and create checkboxes
            $.each(services, function (index, value) {
                services_types.append('<label class="item-select-box"><span class="checkbox"></span>' + value + '</label>');
            });
            services_types.show();
            services_types.find('label').click(function () {
                services_types.find('label').removeClass('selected');
                $(this).addClass('selected');
                services_types.find('input.service_type').val('"' + $.trim($(this).text()) + '"');
            });
        } //end else

        // show hide map and postcode search
        $(step_2_container + ' .section-btn').click(function () {
            $(this).parent().find('a').removeClass('active');
            $(this).addClass('active');

            $(step_2_container + ' .sub-section').hide();
            $(step_2_container).find($($(this).attr('href'))).show();
            // empty the search query for post code and selected area
            $('#query_post_code').val('');
            $('#meta_O_orsand').val('');
            $('#selected_post_code').empty();
            $('.selected_region_details').hide();
            if ($(".austrlian_map").hasClass('selected')) {
                $('.austrlian_map').removeClass('selected');
            }
            if ($("#interactive_image_container").hasClass('selected')) {
                $("#interactive_image_container").removeClass('selected');
                $(".map_hovers img").hide();
            }
        });

        //navigation between steps
        $(step_1_container + ' .controls a[href="' + step_2_container + '"]').click(function (e) {
            e.preventDefault();
            if (!$('input.service_type').val()) {
                $('#location_services_filter').append('<div class="error"><p>Please select a type of service.</p></div>');
            } else {
                $(step_1_container).hide();
                $(step_2_container).show();
                if ($('#location_services_filter .error').length) {
                    $('#location_services_filter .error').remove();
                }
            }
        });
        $(step_2_container + ' .controls a[href="' + step_1_container + '"]').click(function (e) {
            e.preventDefault();
            $(step_2_container).hide();
            $(step_1_container).show();
        });


        //binding click event to search agian button
        $('.search_again').click(function () {
            $('.locate_search_results_body').remove();
            $('#locate_search_results').hide();
            $('#locate_second_step').show();
        });

        //Submit form using AJAX
        var location_search_form = $('.service-provider-search form');
        location_search_form.find('button[type="submit"]').click(function (e) {
            e.preventDefault();

            $.ajax({
                type: "GET",
                url: $(this).parents('form').attr('action'),
                data: $(this).parents('form').serialize(), // serializes the form's elements.
                success: function (data) {
                    $('#locate_second_step').hide();
                    $('#locate_search_results_content').prepend('<div class="locate_search_results_body">' + $(data).find(".content .col4").html() + '</div>');
                    $('#locate_search_results').show();
                    $('#fb-queryform, a[alt="Start over"]').remove();
                    loadResultsPaginationLinks();
                    //initiate google maps on load
                    $('#map_canvas').addClass('googlemap');
                    //initiate the map
                    fbMap.mapInit();
                }
            });
        });

    } //end location search for policy tabs

    /*Careers section Page up hosted pages*/
    if ($('.jobs_left_col').length) {
        $('.pagetitle h1').remove();
        $('.sitetools .print').unbind().removeAttr('target').attr('href', 'javascript:void(0);').attr('onclick', 'window.print()');
    } //end if page up hosted pages

    //Flowchart setup

    // Flowchart Funcs moved out of - $('.flowchart').each(function(index, el) {... from - Line 2407



    $('.flowchart').each(function (index, el) {
        //Cheat way to fix backwards insert
        /*$.fn.reverseChildren = function() {
         return this.each(function(){
         var $this = $(this);
         $this.children().each(function(){ $this.prepend(this) });
         });
         };*/


        // Flowchart Funcs were removed from here - See Line 2325




        $(".flowchart:not(.js-enabled)").each(function () {
            $(this).addClass('js-enabled');
            var rowCount = 0;
            $flowchart = $(this);
            $flowchart.find('.first').each(function (i) {
                rowCount++;
                var elearr = $(this).nextUntil(".last").andSelf();
                var lastele = elearr[elearr.length - 1];
                elearr.push($(lastele).next());
                $.each(elearr, function (i) {
                    $(this).addClass("row-" + rowCount);
                });
            });

            for (i = 1; i <= rowCount; i++) {
                $flowchart.find(".row-" + i).wrapAll("<div class='row'></div>");
            }
            drawRelationships($flowchart);
            setWidth();
        });

        $('.block').each(function (index) {
            if ($('p', this).attr('title')) {
                var tool = $('p', this).attr('title');
                $(this).append('<a href="#" class="tool" title="' + tool + '">?</a>');
                $('p', this).removeAttr('title');
            };
            var width = ($(this).width());
            $('.south', this).css('left', width / 2);
            $('.southlong', this).css('left', width / 2);
            $('.fromNorth', this).css('left', width / 2);
            $('.fromNortheast', this).css('right', Math.floor(width / 10));
            $('.fromNorthwest', this).css('left', Math.floor(width / 10));
        });

        $(document).tooltip({
            open: function (event, ui) {
                if (typeof (event.originalEvent) === 'undefined') {
                    return false;
                }
                var $id = $(ui.tooltip).attr('id');
                // close any lingering tooltips
                $('div.ui-tooltip').not('#' + $id).remove();
            },
            close: function (event, ui) {
                ui.tooltip.hover(function () {
                        $(this).stop(true).fadeTo(400, 1);
                    },
                    function () {
                        $(this).fadeOut('400', function () {
                            $(this).remove();
                        });
                    });
            }
        });
    }); //END $('.flowchart').each(function(index, el) {....

    $('a.tab-link').each(function () {
        $(this).click(function (e) {
            e.preventDefault();
            var href = $(this).attr('href');
            $('.ui-tabs-anchor[href="' + href + '"]').click();
            $('h3[aria-controls="m' + href.substr(1) + '"]').click();
        })
    });

}); //end Document ready

/*
 --------------------
14. Performance AJAX 
 --------------------
*/

(function ($) {
    var $mega = $('li[data-id]');

    if ($mega.length) {

        $mega.each(function () {
            var $this = $(this);
            var id = $this.data('id');
            var $ajaxDiv = $this.find('.megamenu .ajax-response');
            var ajaxUrl = $ajaxDiv.data('ajax-url');
            var $equalDiv = $('.navigation .full-height');
            var $megaMenu = $('.megamenu');

            if (ajaxUrl) {
                $.ajax({
                    url: ajaxUrl + '?root=' + id
                }).done(function (html) {
                    $ajaxDiv.append(html);
                    $ajaxDiv.find('.popular').unwrap();
                    // Fix the equal heights
                    $equalDiv.css('height', '');
                    $megaMenu.css({
                        'display': 'block',
                        'left': '-9999px'
                    });
                    $equalDiv.height(function () {
                        return $(this).outerHeight();
                    });
                    $megaMenu.css({
                        'display': '',
                        'left': ''
                    });
                });
            }
        });
    }
}(jQuery));

/*
 --------------------
14. Popup
 --------------------
*/
/* Popup - TAC-49 */
// JS Cookie - https://github.com/js-cookie/js-cookie
! function (e) {
    var n = !1;
    if ("function" == typeof define && define.amd && (define(e), n = !0), "object" == typeof exports && (module.exports = e(), n = !0), !n) {
        var o = window.Cookies,
            t = window.Cookies = e();
        t.noConflict = function () {
            return window.Cookies = o, t
        }
    }
}(function () {
    function e() {
        for (var e = 0, n = {}; e < arguments.length; e++) {
            var o = arguments[e];
            for (var t in o) n[t] = o[t]
        }
        return n
    }

    function n(o) {
        function t(n, r, i) {
            var c;
            if ("undefined" != typeof document) {
                if (arguments.length > 1) {
                    if ("number" == typeof (i = e({
                            path: "/"
                        }, t.defaults, i)).expires) {
                        var a = new Date;
                        a.setMilliseconds(a.getMilliseconds() + 864e5 * i.expires), i.expires = a
                    }
                    i.expires = i.expires ? i.expires.toUTCString() : "";
                    try {
                        c = JSON.stringify(r), /^[\{\[]/.test(c) && (r = c)
                    } catch (e) {}
                    r = o.write ? o.write(r, n) : encodeURIComponent(String(r)).replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent), n = (n = (n = encodeURIComponent(String(n))).replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent)).replace(/[\(\)]/g, escape);
                    var f = "";
                    for (var s in i) i[s] && (f += "; " + s, !0 !== i[s] && (f += "=" + i[s]));
                    return document.cookie = n + "=" + r + f
                }
                n || (c = {});
                for (var p = document.cookie ? document.cookie.split("; ") : [], d = /(%[0-9A-Z]{2})+/g, u = 0; u < p.length; u++) {
                    var l = p[u].split("="),
                        C = l.slice(1).join("=");
                    '"' === C.charAt(0) && (C = C.slice(1, -1));
                    try {
                        var g = l[0].replace(d, decodeURIComponent);
                        if (C = o.read ? o.read(C, g) : o(C, g) || C.replace(d, decodeURIComponent), this.json) try {
                            C = JSON.parse(C)
                        } catch (e) {}
                        if (n === g) {
                            c = C;
                            break
                        }
                        n || (c[g] = C)
                    } catch (e) {}
                }
                return c
            }
        }
        return t.set = t, t.get = function (e) {
            return t.call(t, e)
        }, t.getJSON = function () {
            return t.apply({
                json: !0
            }, [].slice.call(arguments))
        }, t.defaults = {}, t.remove = function (n, o) {
            t(n, "", e(o, {
                expires: -1
            }))
        }, t.withConverter = n, t
    }
    return n(function () {})
});

$(document).ready(function () {

    var popup = {
        init: function (id) {
            $('#' + id).addClass('visible');
        },
        hide: function (id) {
            $('#' + id).removeClass('visible');
            var cookie_value = Cookies.get('TAC_popup') === undefined ? [] : Cookies.get('TAC_popup').split(',');

            cookie_value.push($('.popup--js').attr('id').replace('popup-', ''));
            cookie_value = cookie_value.join(',');
            Cookies.set('TAC_popup', cookie_value);
        }
    };

    if ($('.popup--js').length) {

        // Bind close popup button 
        $('body').on('click', 'button.popup-close', function () {
            popup.hide($(this).parent('.popup--js').attr('id'));
        });

        // Close on ESC
        $(document).keyup(function (e) {
            if (e.keyCode === 27 && $('.popup--js.visible').length) {
                popup.hide($('.popup--js.visible').attr('id'));
            }
        });

        // Call after X seconds
        var popup_timeout = popup_delay === parseInt(popup_delay, 10) ? popup_delay : 5,
            popupId = $('.popup--js').attr('id'),
            initPopup = Cookies.get('TAC_popup') !== undefined ? (Cookies.get('TAC_popup').split(',').indexOf(popupId.replace('popup-', '')) !== -1 ? false : true) : true;

        if (initPopup) {
            setTimeout(function () {
                popup.init(popupId);
            }, popup_timeout * 1000);
        }
    }

});

/*
 --------------------
16. Search
 --------------------
*/
/* Search - TAC-42 - Changing search box appearance on the tac top navigation */
var changeAutocompletePosition = function () {
    $('#query').autocomplete({
        position: {
            my: "right top",
            at: "right bottom"
        }
    });
    $('.mobile #query').autocomplete({
        position: {
            my: "left top",
            at: "left bottom"
        }
    });
};

$(document).ready(function () {
    changeAutocompletePosition();
});
$(window).load(function () {
    changeAutocompletePosition();
});



/*
--------------------
Modules
--------------------
*/