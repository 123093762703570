(function($) {
    var arrowMarkupNext = '<button type="button" class="slick-next"><span class="visuallyhidden">Next</span></button>',
        arrowMarkupPrevious = '<button type="button" class="slick-prev"><span class="visuallyhidden">Previous</span></button>',
        carouselAnimationSpeed = 500,
        carouselAutoplaySpeed = 7000,
        carouselContainerSelector = '.js-carousel-container',
        carouselSelector = '.js-carousel',
        gallerySelector = '.js-gallery',
        galleryNavSelector = '.js-gallery-nav',
        $carousel = $(carouselSelector),
        $gallery = $(gallerySelector),
        $galleryNav = $(galleryNavSelector);

    if ($(carouselContainerSelector).length) {

        // Init event needs to be declared before carousel is initialised
        $carousel.on('init', function(ev, carousel) {
            // Hide "play" control
            carousel.$slider.parents(carouselContainerSelector).eq(0).find('.slick-play').hide();
        });

        // Inside Page Carousel: Init
        $carousel.each(function(i, el) {
            var $currentCarousel = $(el),
                configAutoplay = $currentCarousel.data('autoplay') ? true : false,
                configIndicators = configAutoplay;
            $currentCarousel.slick({
                adaptiveHeight: true,
                autoplay:       configAutoplay,
                autoplaySpeed:  carouselAutoplaySpeed,
                arrows:         true,
                cssEase:        'linear',
                dots:           configIndicators,
                fade:           true,
                infinite:       true,
                nextArrow:      arrowMarkupNext,
                prevArrow:      arrowMarkupPrevious,
                slide:          '.internal-carousel__item',
                speed:          carouselAnimationSpeed
            });
        });

        // Inside Page Carousel: Configure Pause/Play Controls
        $('.slick-control').on('click.carouselControls', function(ev) {
            var $currentControl = $(ev.currentTarget),
                $pauseControl = $currentControl.siblings('.slick-pause'),
                $playControl = $currentControl.siblings('.slick-play'),
                $currentCarousel = $currentControl.parents(carouselContainerSelector).eq(0).find(carouselSelector),
                doPause = $currentControl.hasClass('slick-pause');
            $currentControl.hide();
            if (doPause) {
                $currentCarousel.slick('slickPause');
                $playControl.show();
            }
            else {
                $currentCarousel.slick('slickPlay');
                $pauseControl.show();
            }
        });

    }

    // Image Gallery Slides: Init
    $gallery.slick({
        adaptiveHeight: true,
        asNavFor:       galleryNavSelector,
        arrows:         true,
        cssEase:        'linear',
        dots:           false,
        fade:           true,
        infinite:       true,
        nextArrow:      arrowMarkupNext,
        prevArrow:      arrowMarkupPrevious,
        slide:          '.slick-slides__slide',
        speed:          carouselAnimationSpeed
    });

    // Image Gallery Nav: Init
    $galleryNav.slick({
        arrows:        false,
        asNavFor:      gallerySelector,
        centerMode:    true,
        focusOnSelect: true,
        infinite:      true,
        slide:         '.slick-nav__slide',
        slidesToShow:  5
    });

}(jQuery));
